import React from 'react';
import styles from './styles.module.css'

export default class Error404Component extends React.Component {
  render() {
    return (
      <div className={styles.Container}>
      <div className={styles.Centered}>
        <h1 className={styles.H1}>404</h1>
        <h2 className={styles.H2}>Not found</h2>
      </div>
      </div>
    )
  }
}