export interface IUser{
    id: number
    first_name: string
    last_name: string
    email: string
    nickname: string
    role_id: number
}

export class User{
    id: number = 0
    first_name: string = ''
    last_name: string = ''
    email: string = ''
    nickname: string = ''
    role_id: number = 0

    public get Id() : number {
        return this.id
    }
    
    public get FirstName() : string {
        return this.first_name
    }

    public get LastName() : string {
        return this.last_name
    }
    
    public get Email() : string {
        return this.email
    }
    
    public get Nickname() : string {
        return this.nickname
    }
    
    public get RoleId() : number {
        return this.role_id
    }
    
    public RoleName() : string {
        return UserRoles[this.role_id]
    }

    constructor(o:IUser){
        this.id = o.id
        this.first_name = o.first_name
        this.last_name = o.last_name
        this.email = o.email
        this.nickname = o.nickname
        this.role_id = o.role_id
    }   
    
}

 const UserRoles = [
     'Not selected',
     'Admin',
     'Manager',
     'Technician',
     'Phone Representative',
     'Approval',
     'Accountant',
     'Telemarketing',
     'Sales',
     'Installer',
     'Dispatch', // 11
     'Quality agent', //12
 ]