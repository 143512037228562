import React from 'react';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css'
import { Dialog, DialogTitle, DialogActions, DialogContent, Grid, IconButton, Container } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { CustomSkill } from '../../models/AgentSkills/CustomSkill';
import { RedInverseBtn } from '../Styled/StyledButtons';
import DeleteSkillApiError from '../../models/ApiErrors/DeleteSkillApiError';

export interface IProps {
    open: boolean
    customSkill: CustomSkill
    error: DeleteSkillApiError
    toggle: () => void
}

export default class CustomSkillDeleteComponent extends React.Component<IProps> {

    render() {
        return (<div>
            <Dialog open={ this.props.open } onClose={this.props.toggle}>
                <DialogTitle className={styles.PopUpHeader}>
                    "{this.props.customSkill.Key}" can`t be deleted
          <IconButton className={mainStyles.floatRight} onClick={this.props.toggle}>
                        <ClearIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Container maxWidth={false}>
                        <Grid container>
                            <Grid item xs={12}>
                                <p className={styles.Error}>{this.props.error.Message}</p>

                                <div className={(this.props.error.Queues.length > 0) ? '' : styles.DisplayNone}>
                                <div><label className={styles.StepFormLabel}>In queues:</label></div>
                                <div>
                                    <ul className={styles.ErrorsUl}>
                                        {
                                            this.props.error.Queues.map((queue, index) => {
                                                return (<li key={index}><a className={styles.ReferenceLink} href={'/queue/sid/' + queue.Sid} target="_blank"rel="noopener noreferrer">{queue.FriendlyName}</a></li>)
                                            })
                                        }
                                    </ul>
                                    </div>
                                </div>

                                <div className={(this.props.error.Workflows.length > 0) ? '' : styles.DisplayNone}>
                                    <div><label className={styles.StepFormLabel}>In workflows:</label></div>
                                    <div>
                                    <ul className={styles.ErrorsUl}>
                                    {
                                        this.props.error.Workflows.map((workflow, index) => {
                                            return (
                                            <div key={index}>
                                                <li><a className={styles.ReferenceLink} href={'/workflow/sid/' + workflow.Sid} target="_blank" rel="noopener noreferrer">{workflow.FriendlyName}</a>
                                                <ul className={styles.ErrorsUl}>
                                                    {
                                                        workflow.Steps.map((step, index) => {
                                                            return (<li key={index}>{step}</li>)
                                                        })
                                                    }
                                                </ul>
                                                </li>
                                            </div>
                                            )
                                        })
                                    }
                                    </ul>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Container maxWidth={false}>
                        <div style={{ marginBottom: '24px' }}>
                            <Grid container >
                                <Grid item xs={12}>
                                    <RedInverseBtn fullWidth={true} onClick={this.props.toggle}>Cancel</RedInverseBtn>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </DialogActions>
            </Dialog>

        </div>
        )
    }
}

