import React from 'react';
import { ApiUrl } from '../../config/api';
import styles from './styles.module.css'
export default class Error401Component extends React.Component {
  render() {
    return (
      <div className={styles.Container}>
      <div className={styles.Centered}>
        <h1 className={styles.H1}>401</h1>
        <h2 className={styles.H2}>Unauthorized</h2>
        <a href={ApiUrl} className={styles.Button} >Login page</a>
      </div>
      </div>
    )
  }
}