export interface IExpressionConfig {
    fields: IExpressionConfigProperty
}

export interface IExpressionConfigProperty {
    [key: string]: any
}
export class ExpressionConfigProperty {
    [key: string]: any
}
export class ExpressionOption {
    name: string
    value: string | number | boolean
    constructor(name:string, value:string | number | boolean){
        this.name = name
        this.value = value
    }
}
export enum ExpressionConditions{
    AND = "AND",
    OOR = "OR"
} 
export class Expression {
    public condition: ExpressionConditions = ExpressionConditions.AND
    
    public rules: ExpressionRule[] | Expression[] = []
}

export class ExpressionRule {
    public field: string

    public operator: string

    public value: string[] | string | number[] | number

    constructor(field: string, operator: string, value: string[] | string | number[] | number){
        this.field = field
        this.operator = operator
        this.value = value
    }
}