import React from 'react';
import { AuthActions } from '../../actions/AuthActions';
import { User } from '../../models/Layout/User/User';
import SidebarComponent from './SidebarComponent';
import styles from './styles.module.css'
import history from '../../components/history';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ApiUrl } from '../../config/api';

interface IState {
    openMenu: boolean;
    user: User | null;
    auth: boolean;
    minimized: boolean
}

interface IProps{
    children: React.ReactNode
}

export default class LayoutComponent extends React.Component<IProps,IState> {
    state: IState = {
        openMenu: true,
        user: null,
        auth: false,
        minimized: false
    }

    componentDidMount() {
        this.loadUser()
        this.onResize()
        window.addEventListener('resize', ()=>{this.onResize()})
    }

    componentWillUnmount(){
        window.removeEventListener('resize', ()=>{this.onResize()})
    }

    onResize(){
        this.setState({openMenu: (window.innerWidth > 650), minimized: !(window.innerWidth > 650)})
    }

    loadUser() {
        AuthActions.getUser().then((data) => {
            if (data instanceof User) {
                this.setState({ user: data, auth: true })

            } else {
                this.setState({ auth: false })
                history.push('/401');
            }
        })
    }

    toggleSidebar() {
        this.setState({ openMenu: !this.state.openMenu });
    }

    to401() {
        history.push('/401');
    }

    logOut(){
        localStorage.removeItem('token')
        window.location.href = ApiUrl
    }
    render() {
        return (
            <div>
                <div className={`${styles.nav} ${(!this.state.openMenu || this.state.minimized ? styles.navMax : '')} ${this.state.minimized ? styles.navMin : ''}`}>
                    <div className={styles.UserBlock }>
                        <div className={styles.UserBlock__name}>{this.state.user?.FirstName} {this.state.user?.LastName}</div> 
                        <button className={styles.UserBlock__logoutBtn} onClick={()=>this.logOut()}><ExitToAppIcon /></button>
                    </div>
                </div>
                <SidebarComponent open={this.state.openMenu} minimized={this.state.minimized} toggleOpen={() => this.toggleSidebar()} />

                <main className={`${styles.layout} ${(!this.state.openMenu || this.state.minimized? styles.layoutMax  : '')} ${this.state.minimized? styles.layoutMin  : ''}`}>
                    {this.props.children}
                </main>
            </div>
        )
    }
}