import { Grid, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import styles from '../styles.module.css';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RoutingStep } from '../../../models/Workflows/RoutingStep';
import { BlueInverseBtn } from '../../Styled/StyledButtons';
import WorkflowQueueComponent from '../WorkflowStepComponent';
import { Queue } from '../../../models/Queue/Queue';
import { IFilter } from '../../../models/Workflows/Filter';

import ClearIcon from '@material-ui/icons/Clear';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { HumanFriendlyExpressionConverter } from '../../../models/ExpressionBuilder/HumanFriendlyExpressionConverter';
import { WorkersTargetConverter } from '../../../models/ExpressionBuilder/WorkersTargetConverter';
import { ExpressionOption, IExpressionConfig } from '../../../models/ExpressionBuilder/ExpressionBuilderTypes';
import WorkflowExpresionComponent from '../WorkflowExpresionComponent';
import { DepartmentsActions } from '../../../actions/DepartmentsActions';

interface IProps {
    currentWorkspace: string,
    queues: Queue[]
    filter: IFilter
    handleDeleteFilter: (target: IFilter) => void
    handleChangeFilter: (filter: IFilter) => void
}

interface IState {
    minimized: boolean
    showTaskExpression: boolean
    workflowExpressionConfig: IExpressionConfig,
    workflowExpressionDefaultData: object,
}

export default class WorkflowFilterComponent extends React.Component<IProps, IState> {
    state: IState = {
        minimized: false,
        showTaskExpression: false,

        workflowExpressionConfig: {
            fields: {
                category: { name: 'Departments', type: 'category', options: [], operators: ['='] },
                exist_customer: { name: 'Exist customer', type: 'boolean', operators: ['='] },
                is_member: { name: 'Is Member', type: 'boolean', operators: ['='] },
                avg_call_value_scaled: { name: 'AVG Call Value (scaled)', type: 'number', operators: ['=', '!=', '<=', '>=', '<', '>'] },
                line_type: { name: 'Line Type', type: 'category', options: [{ name: 'landline', value: 'landline' }, { name: 'wireless', value: 'wireless' }, { name: 'voip', value: 'voip' }, { name: 'other', value: 'other' }, { name: 'NULL', value: 'NULL' }], operators: ['in', 'not in', 'has'] },
            }
        },
        workflowExpressionDefaultData: {
            condition: 'and',
            rules: [
                { field: 'category', operator: '=' },
                { field: 'exist_customer', operator: '=', value: false },
                { field: 'is_member', operator: '=', value: false },
            ]
        },
    }
    componentDidMount() {
        this.loadDepartments()
    }
    loadDepartments() {
        DepartmentsActions.getDepartments()
            .then(departments => {
                let deps: ExpressionOption[] = []

                departments.forEach((item) => {
                    deps.push(new ExpressionOption(item.Name, item.Id))
                })

                let workflowConfig = this.state.workflowExpressionConfig
                workflowConfig.fields.category.options = deps

                this.setState({ workflowExpressionConfig: workflowConfig })
            })
    }
    addNewTarget() {
        let filter = this.props.filter
        filter.routing_steps.push(new RoutingStep(undefined))
        this.props.handleChangeFilter(filter)
    }
    handleChangeRoutingStep(step: RoutingStep) {
        let filter = this.props.filter
        filter.routing_steps.forEach((element, index) => {
            if (element.Guid === step.Guid) {
                filter.routing_steps[index] = step
                return 0;
            }
        });
        this.props.handleChangeFilter(filter)
    }
    handleDeleteRoutingStep(step: RoutingStep) {
        let filter = this.props.filter
        filter.routing_steps.forEach((element, index) => {
            if (element.Guid === step.Guid) {
                filter.routing_steps.splice(index, 1)
                return 0;
            }
        });
        this.props.handleChangeFilter(filter)
    }
    onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const o = this.props.filter

        o.routing_steps = this.reorder(
            this.props.filter.routing_steps,
            result.source.index,
            result.destination.index
        )
        this.props.handleChangeFilter(o)
    }

    reorder(list: RoutingStep[], startIndex: number, endIndex: number) {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    toggleMinimize() {
        this.setState({ minimized: !this.state.minimized })
    }
    handleName(name: string) {
        let filter = this.props.filter
        filter.friendly_name = name
        this.props.handleChangeFilter(filter)
    }
    toggleTaskExpression() {
        this.setState({ showTaskExpression: !this.state.showTaskExpression })
    }
    handleTaskExpression(exp: string) {
        let filter = this.props.filter
        filter.expression_json = exp
        filter.expression = WorkersTargetConverter.ConvertToTwilio(JSON.parse(exp), '')
        this.props.handleChangeFilter(filter)
        this.setState({ showTaskExpression: false })
    }
    render() {
        return (
            <div className={` ${styles.RouteStepContainer} ${((this.props.filter.friendly_name === '') || (this.props.filter.expression === '')) ? styles.FormInvalid : ''}`}>
                <Grid container spacing={1}>
                    <Grid item xs={12} onClick={() => { this.toggleMinimize() }}>
                        <DragIndicatorIcon className={styles.DragIcon} />
                        <label className={styles.StepFriendlyName}>{this.props.filter.friendly_name}</label>
                        <KeyboardArrowUpIcon className={`${styles.MinimazeIcon} ${this.state.minimized ? styles.DisplayNone : ''}`} onClick={() => { this.toggleMinimize() }} />
                        <KeyboardArrowDownIcon className={`${styles.MinimazeIcon} ${!this.state.minimized ? styles.DisplayNone : ''}`} onClick={() => { this.toggleMinimize() }} />
                        <ClearIcon className={styles.RemoveIcon} onClick={() => { this.props.handleDeleteFilter(this.props.filter) }} />
                    </Grid>
                </Grid>
                <Grid container spacing={1} className={`${this.state.minimized ? styles.DisplayNone : ''}`}>
                    <Grid item xs={12} md={6}>
                        <div>
                            <label className={styles.StepFormLabel}>Friendly Name <Tooltip title='Step Friendly Name' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                            <input placeholder="Friendly Name" className={`${(this.props.filter.friendly_name === '') ? styles.FormInvalid : ""} ${styles.FullWidth} ${styles.FormControl}`} value={this.props.filter.friendly_name} onChange={event => this.handleName(event.target.value)} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div>
                            <label className={styles.StepFormLabel}>Task Expression <Tooltip title='An expression to select tasks to assign. Tasks that do not match will evaluate against the next filter.' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                            <div
                                className={`${(this.props.filter.expression === '') ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`}
                                dangerouslySetInnerHTML={{ __html: (this.props.filter.expression_json !== '') ? HumanFriendlyExpressionConverter.ConvertToTwilio(JSON.parse(this.props.filter.expression_json), 'task', this.state.workflowExpressionConfig) : '' }}
                                onClick={() => this.toggleTaskExpression()} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}><div className={styles.SubHead}><span >ROUTING STEPS</span></div></Grid>
                    <DragDropContext onDragEnd={(result: any) => this.onDragEnd(result)}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div style={{ width: "100%" }}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {this.props.filter.routing_steps.map((step: RoutingStep, index: number) => (
                                        <Draggable key={step.Guid} draggableId={step.Guid} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <WorkflowQueueComponent
                                                        currentWorkspace={this.props.currentWorkspace}
                                                        target={step}
                                                        queues={this.props.queues}
                                                        handleDelete={() => this.handleDeleteRoutingStep(step)}
                                                        handleChange={() => this.handleChangeRoutingStep(step)} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Grid container justify={'flex-end'}>
                        <Grid item xs={12} md={6}>
                            <div className={styles.ButtonMargin}>
                                <BlueInverseBtn variant="contained" fullWidth={true} onClick={() => this.addNewTarget()}><Add /> add step</BlueInverseBtn>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>


                <WorkflowExpresionComponent config={this.state.workflowExpressionConfig} defaultExpression={this.state.workflowExpressionDefaultData} expression_json={this.props.filter.expression_json} editShow={this.state.showTaskExpression} toggleHandle={() => this.toggleTaskExpression()} saveHandle={(taskExpressionJson) => this.handleTaskExpression(taskExpressionJson)} />
            </div >
        )
    }
}