import React from 'react';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';
import { Department } from '../../models/AgentSkills/Department';

export interface IProps {
  departments: Department[],
  agentDepartments: number[],
  handleChange: (dep: number, value: boolean) => void
}

export default class DepartmentsComponent extends React.Component<IProps> {
  private componentBody = React.createRef<HTMLDivElement>()
  valid() {
    return (this.props.agentDepartments.length > 0)
  }
  isAllChecked(){
    let isAll = true
    this.props.departments.forEach((dep)=>{
      if(this.props.agentDepartments.indexOf(dep.Id) < 0){
        isAll = false
      }
    })
    return isAll;
  }
  handleCheckedAll(){
    let checked = true
    if(this.isAllChecked()){
      checked = false
    }
    this.props.departments.forEach(dep => {
      this.props.handleChange(dep.Id, checked)
    });
  }

  arraySize(){
    let counter = 0
    this.props.agentDepartments.forEach(()=>{
      counter++
    })
    return counter
  }

  render() {
    let error = <div></div>
    if (!this.valid()) { error = <div className={styles.Error}>Select one or more items</div> }
    return (
      <div>
        <div className={mainStyles.ComponentHeader}><span >Departments</span><span className={mainStyles.HeadRightCounter}> {this.arraySize()}</span><span className={(this.componentBody && this.componentBody.current && this.componentBody.current.clientWidth>330)?mainStyles.FloatRight:''}>&nbsp;Operation Categories</span></div>
        {error}
        <div ref={this.componentBody} className={`${mainStyles.ComponentBody} ${mainStyles.FlexColScroll} ${(!this.valid()) ? styles.ComponentInvalid : ''} `} >
        <label className={`${styles.SkillsCheckbox} ${styles.Underlined}`} >
                <input type="checkbox" value={-1} checked={this.isAllChecked()} onChange={() => this.handleCheckedAll()} />
                <span className={`${(this.isAllChecked()) ? styles.CheckLabelChecked : styles.CheckLabel}`}>ALL DEPARTMENTS</span>
              </label>
          {this.props.departments.map((dep: Department) => {
            return (
              <label className={`${styles.SkillsCheckbox} ${styles.Underlined}`} key={dep.Id} >
                <input type="checkbox" value={dep.Id} checked={(this.props.agentDepartments.indexOf(dep.Id) >= 0)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleChange(parseInt(event.target.value), event.target.checked)} />
                <span className={`${(this.props.agentDepartments.indexOf(dep.Id) >= 0) ? styles.CheckLabelChecked : styles.CheckLabel}`}>{dep.Name}</span>
              </label>
            )
          })}
        </div>
      </div>
    )
  }
}
