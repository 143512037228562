export interface ICustomSkill{
  id:number
  type_id: number
  key:string
}
export class CustomSkill {
    private id: number = 0;
    public get Id() : number {
      return this.id
    }
  
    public set Id(v : number) {
      this.id = v
    }
    
    private key: string = ''
    public get Key() : string {
      return this.key
    }  
    public set Key(v : string) {
      this.key = v
    }  
    
    private type_id: number = -1    
    public get TypeId() : number {
      return this.type_id
    }    
    public set TypeId(v : number) {
      this.type_id = v;
    }    

    private _created: number = 0
    public get Created(){
      return this._created
    }
    constructor(o: ICustomSkill | undefined | 'undefined' = undefined){
      if(o !== undefined && o !== 'undefined'){
      this.id = o.id
      this.type_id = o.type_id
      this.key = o.key
      }
      this._created = (new Date()).getTime();
    }
  }