import React from 'react';
import styles from './styles.module.css';
import { Container, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { Add, DeleteForever } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import CreateIcon from '@material-ui/icons/Create';
import PersonIcon from '@material-ui/icons/Person';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import { Alert } from '@material-ui/lab';
import { Agent } from '../../models/AgentSkills/Agent';
import { AgentSkillsActions, IAxiosAgents } from '../../actions/AgentSkillsActions';
import { Pages } from '../../models/Pages';
import Pagination from '@material-ui/lab/Pagination';
import { IError } from '../../models/IError';
import { Team } from '../../models/Teams/Team';
import { TeamsActions } from '../../actions/TeamsActions';
import ConfirmComponent from '../ConfirmComponent/ConfirmComponent';

interface IState {
  agents: Agent[],
  backdrop: boolean,
  alert: boolean,
  errorAlert: boolean,
  errors: string[],
  pagination: Pages | null
  filter: AgentsFilter,
  searchStatus: string,
  teams: Team[],
  agentName: string
  deleteConfirmShow: boolean
  agentId: number
}

class AgentsFilter {
  search?: string
  team_id?: string
  agents?: string
  page?: string

  toString(): string {
    let arr: string[] = []
    if (this.page) arr.push('page=' + this.page)
    if (this.team_id) arr.push('team_id=' + this.team_id)
    if (this.agents) arr.push('agents=' + this.agents)
    if (this.search) arr.push('search=' + this.search)

    return arr.join('&')
  }
}

export default class AgentsComponent extends React.Component<any, IState> {

  state: IState = {
    teams: [],
    agents: [],
    backdrop: true,
    alert: false,
    errorAlert: false,
    errors: [],
    pagination: null,
    filter: new AgentsFilter(),
    searchStatus: '',
    agentName: '',
    deleteConfirmShow: false,
    agentId: 0
  }
  loadTeams() {
    TeamsActions.getTeams()
      .then(teams => { this.setState({ teams: teams }) })
  }
  componentDidMount() {
    document.title = 'Agents'
    this.setState({ backdrop: true })
    this.loadTeams()
    this.agents()
  }

  agents() {

    AgentSkillsActions.getAgents(this.state.filter.toString())
      .then((data: IAxiosAgents) => {
        let agents: Agent[] = []
        data.data.data.forEach(agent => {
          agents.push(new Agent(agent))
        });
        let pages = new Pages(data.data.per_page, data.data.curr_page, data.data.last_page)

        this.setState({ agents: agents, pagination: pages, backdrop: false })

      })
      .catch((data: IError) => {
        let errors = (data.errors === undefined) ? [data.error] : data.errors
        this.setState({ errorAlert: true, backdrop: false, errors: errors })
      })
  }

  getTeamName(id: number | null) {
    const teams = this.state.teams
    for (let i in teams) {
      if (teams[i].Id === id) {
        return teams[i].Name
      }
      return '<< undefined >>'
    }

  }

  handleClose() {
    this.setState({ alert: false })
  }
  handleCloseError() {
    this.setState({ errorAlert: false })
  }
  handleChangePage(page: number) {
    const filter = this.state.filter
    filter.page = page.toString()
    this.setState({ filter: filter })
    this.agents()
  }
  timeoutID: number | null = null
  handleChangeSearch(search: string) {
    const filter = this.state.filter
    filter.search = search
    this.setState({ filter: filter })
    this.setState({ searchStatus: 'loading' });
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
    this.timeoutID = window.setTimeout(() => {
      this.agents()
      this.setState({ searchStatus: 'loaded' })
    }, 1500)

  }
  handleAccess(agentId: number, isEnabled: number) {
    AgentSkillsActions.accessAgent(agentId, (isEnabled === 1))
      .then(agent => {
        if (agent instanceof Agent) {
          this.agents()
        }
      })
  }
  deleteAgent() {
    AgentSkillsActions.deleteAgent(this.state.agentId)
      .then((data) => {
        this.closeDeleteConfirm()
        if (typeof data === "number") {
          this.agents()
        } else {
          let errors = (data.errors === undefined) ? [data.error] : data.errors
          this.setState({ errors: errors, errorAlert: true })
        }
      })
  }
  closeDeleteConfirm() {
    this.setState({ deleteConfirmShow: false })
  }
  handleAgents(value:string){
    const filter = this.state.filter
    filter.agents = (value !== '')? value: undefined
    this.setState({ filter: filter })
    this.agents()
  }
  handleTeam(value:string){
    const filter = this.state.filter
    filter.team_id = (value !== '')? value: undefined
    this.setState({ filter: filter })
    this.agents()
  }
  render() {
    return (
      <div>
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={mainStyles.Header}> &nbsp;</div>
                  <div className={mainStyles.AgentName}>Agents Management</div>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>

                  <div className={styles.filters__search}>
                    {this.state.searchStatus !== 'loading' ?
                      <span
                        className={styles.filters__search_clear}
                        onClick={() => this.handleChangeSearch('')}>
                        <ClearIcon className={styles.filters__search_loading} />
                      </span>
                      :
                      <span className={styles.filters__renderSearch__loading}>
                        <CircularProgress size={24} className={styles.filters__search_loading} />
                      </span>
                    }
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => this.handleChangeSearch(e.target.value)}
                      value={this.state.filter.search}
                      className={styles.filters__search__inputSearch}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <select className={`${styles.FormControl} ${styles.FullWidth}`} value={this.state.filter.team_id} onChange={event => this.handleTeam(event.target.value as string)} >
                    <option value="">(any team)</option>
                    <option value="true">With Team</option>
                    <option value="false">Without Team</option>
                    {this.state.teams.map((row, index) => (
                                        <option key={row.Id} value={row.Id} >{row.Name}</option>
                                    ))}
                  </select>
                </Grid>
                <Grid item xs={12} md={3}>
                  <select className={`${styles.FormControl} ${styles.FullWidth}`} value={this.state.filter.team_id} onChange={event => this.handleAgents(event.target.value as string)} >
                    <option value="">(All)</option>
                    <option value="true">Agents only</option>
                    <option value="false">Users only</option>
                  </select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" className={styles.AgentIcon}></TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="center">Team</TableCell>
                      <TableCell align="center">Twilio Access</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={mainStyles.FlexColScroll}>
                    {this.state.agents.map((row, index) => (
                      <TableRow key={row.Id}>

                        <TableCell align="right" className={styles.AgentIcon}>{(!row.IsNew) ? <PersonIcon /> : ''}</TableCell>
                        <TableCell align="left">{row.FirstName + ' ' + row.LastName + ' ' + row.Nickname}</TableCell>
                        <TableCell align="center">{(!row.IsNew) ? this.getTeamName(row.TeamId) : ''}</TableCell>
                        <TableCell align="center">
                          {(!row.IsNew) ?
                            <div className={styles.SwitchContainer}>
                              <div className={styles.Switch} >
                                <input type="radio" id={"switch_left_" + row.Id} name={"switch" + row.Id} value="0" checked={!row.TwilioAccess} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAccess(row.Id, parseInt(event.target.value))} />
                                <label htmlFor={"switch_left_" + row.Id}>Disabled</label>
                                <input type="radio" id={"switch_right_" + row.Id} name={"switch" + row.Id} value="1" checked={row.TwilioAccess} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAccess(row.Id, parseInt(event.target.value))} />
                                <label htmlFor={"switch_right_" + row.Id} >Enabled</label>
                              </div>
                            </div>
                            :
                            ""
                          }
                        </TableCell>
                        <TableCell align="right">
                          {(!row.IsNew) ?
                            <Button color="primary" href={'/agent-skills/id/' + row.Id} ><CreateIcon /></Button>
                            :
                            <Button color="default" href={'/agent-skills/id/' + row.Id} ><Add /></Button>}
                          {(!row.IsNew) ? <Button color="secondary" onClick={() => { this.setState({ deleteConfirmShow: true, agentName: row.FirstName + " " + row.LastName, agentId: row.Id }) }}><DeleteForever /></Button> : ''}
                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </TableContainer>
              {(this.state.pagination !== null) ?
                <Grid item xs={12} >

                  <Pagination
                    className={styles.Pagination}
                    count={this.state.pagination.LastPage}
                    page={this.state.pagination.CurrentPage}
                    onChange={(event: React.ChangeEvent<unknown> | null, newPage: number) => this.handleChangePage(newPage)}
                    variant="outlined" shape="rounded" />
                </Grid> : ''
              }
            </Grid>
          </Grid>
        </Container>
        <Snackbar open={this.state.alert} autoHideDuration={5000} onClose={() => this.handleClose()} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Alert onClose={() => this.handleClose()} severity="success" variant="filled">
            Saved!</Alert>
        </Snackbar>
        <Snackbar open={this.state.errorAlert} autoHideDuration={5000} onClose={() => this.handleCloseError()} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Alert onClose={() => this.handleCloseError()} severity="error" variant="filled">
            Error<br />
            <div className={styles.AlertError}>{this.state.errors}</div>
          </Alert>
        </Snackbar>
        <Backdrop className={styles.Backdrop} open={this.state.backdrop}>
          <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
          <p className={styles.BackdropProgress}>Loading...</p>
        </Backdrop>
        <ConfirmComponent show={this.state.deleteConfirmShow} title={"Delete Agent " + this.state.agentName} bodyText="Are you sure you want to delete agent?" close={() => this.closeDeleteConfirm()} confirm={() => this.deleteAgent()}></ConfirmComponent>
      </div>
    )
  }
}


