import { LocationBrandSettings } from "../models/LocationBrands/LocationBrandsSettings";
import Api from "./../config/api";

export class LocationBrandsActions {
    static get(): Promise<LocationBrandSettings[]> {
        return Api.get(`/location-brand/settings`)
            .then((res: any) => { return res.data })
    }
    static getIvrTypes(): Promise<LocationBrandSettings[]> {
        return Api.get(`/location-brand/ivr-types`)
            .then((res: any) => { return res.data })
    }
    static update(settings: LocationBrandSettings[]): Promise<LocationBrandSettings[]> {
        return Api.put(`/location-brand/settings`, settings)
            .then((res: any) => { return res.data })
    }
    static uploadAudio(file:File): Promise<any>{
        let formData = new FormData();
    
        formData.append("file", file);
    
        return Api.post("/location-brand/upload-ivr-audio", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res: any) => { return res.data });
      }
}