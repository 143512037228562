export interface ITaskChannel{
    sid: string,
    friendly_name: string
}

export class TaskChannel{
    private sid: string
    private friendly_name: string 
    
    public get Sid() : string {
        return this.sid
    }
    
    public get Name() : string {
        return this.friendly_name
    }

    constructor(o: ITaskChannel){
        this.sid = o.sid
        this.friendly_name = o.friendly_name
    }  

}