import { Expression, ExpressionOption, ExpressionRule, IExpressionConfig } from "./ExpressionBuilderTypes";

export class HumanFriendlyExpressionConverter {

    public static ConvertToTwilio(expression: Expression, prefix: string = '', config: IExpressionConfig) {
        let arr: string[] = []

        if (expression.rules.length === 0) {
            return '1==1'
        }

        try {
            expression.rules.forEach((item: any) => {
                if (item.condition === undefined) {
                    arr.push(this.parseRule(item, prefix, config))
                }
                if (item.condition !== undefined) {
                    let subArr = this.parseExpression(item, prefix, config)
                    if (subArr) {
                        arr.push(subArr)
                    }
                }
            });
        }
        catch {
            return ''
        }

        return arr.join(' <br/><span>' + expression.condition.toUpperCase() + '</span> ')
    }

    private static parseRule(element: ExpressionRule, prefix: string = '', config: IExpressionConfig) {
        let value: number[] | string[] | number | string = element.value
        if (Array.isArray(value)) {

            let names: string[] = []
            value.forEach((val: number | string) => {
                names.push(this.findName(config.fields[element.field].options, val))
            })
            value = '[\'' + names.join('\',\'') + '\']'
        } else {
            if (config.fields[element.field].type === 'category') {
                value = '\'' + this.findName(config.fields[element.field].options, value) + '\''
            } else {
                value = this.getVal(value)
            }
        }
        if (element.field === 'exist_customer') return (value === 'true') ? 'Task.ExistCustomer TRUE': 'Task.ExistCustomer FALSE'
        return ((prefix !== '') ? prefix + '.' : '') + config.fields[element.field].name + " " + this.friendlyOperator(element.operator) + " " + value
    }
    private static getVal(elem: (number | string | boolean)): string {
        if (typeof elem === 'number') {
            return elem.toString()
        }
        if (typeof elem === 'boolean') {
            return (elem) ? 'true' : 'false'
        }
        return '\'' + elem + '\''
    }

    private static findName(fields: ExpressionOption[], value: number | string) {
        let name = ''
        fields.forEach(element => {
            if (element.value === value) {
                name = element.name
                return
            }
        });
        return name
    }

    private static friendlyOperator(operator: string) {
        let name = ''
        switch (operator) {
            case '=':
                name = 'equal'
                break
                case '!=':
                name = 'not equal'
                break
            case '>':
                name = 'greater than'
                break
            case '<':
                name = 'less than'
                break
            case '>=':
                name = 'greater or equal'
                break
            case '<=':
                name = 'less or equal'
                break
            default:
                name = operator
                break;
        }
        return ` <span>${name.toUpperCase()}</span> `
    }

    private static parseExpression(element: Expression, prefix: string = '', config: IExpressionConfig) {
        let arr: string[] = []

        if (element.rules.length === 0) {
            return false
        }

        element.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                arr.push(this.parseRule(item, prefix, config))
            }
            if (item.condition !== undefined) {
                let subArr = this.parseExpression(item, prefix, config)
                if (subArr) {
                    arr.push(subArr)
                }
            }
        });
        return '(' + arr.join(' <br/><span>' + element.condition.toUpperCase() + '</span> ') + ')'
    }
 
    private static parseSortExpression(element: Expression, prefix: string = '') {
        let arr: string[] = []

        if (element.rules.length === 0) {
            return ''
        }

        element.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                arr.push(this.parseSortRule(item, prefix))
            }
            if (item.condition !== undefined) {
                let subArr = this.parseSortExpression(item, prefix)
                if (subArr) {
                    arr.push(subArr)
                }
            }
        });
        return '(' + arr.join(' <br/><span>' + element.condition.toUpperCase() + '</span> ') + ')'
    }

    private static parseSortRule(element: ExpressionRule, prefix: string = '') {
        return ((prefix !== '') ? prefix + '.' : '') + element.field + " <span>" + element.value + "</span> "
    }

    public static ConvertSortingToTwilio(expression: Expression, prefix: string = '') {
        let arr: string[] = []

        if (expression.rules.length === 0) {
            return ''
        }

        expression.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                arr.push(this.parseSortRule(item, prefix))
            }
            if (item.condition !== undefined) {
                let subArr = this.parseSortExpression(item, prefix)
                if (subArr) {
                    arr.push(subArr)
                }
            }
        });

        return arr.join(' <br/><span>' + expression.condition.toUpperCase() + '</span> ')
    }
}