export interface IQueue {
    sid: string
    friendly_name: string
    tasks_order: string
    assignment_activity_sid: string
    reservation_activity_sid: string
    max_reserved_workers: number
    target_workers_json: string
    target_workers: string
}
export class Queue {
    private sid: string = '';
    private friendly_name: string = '';
    private tasks_order: string = 'FIFO';
    private assignment_activity_sid: string = '';
    private reservation_activity_sid: string = '';
    private max_reserved_workers: number = 0;
    private target_workers_json: string = '';
    private target_workers: string = '';

    constructor(o: IQueue | undefined) {
        if (o !== undefined) {
            this.sid = o.sid
            this.friendly_name = o.friendly_name
            this.tasks_order = o.tasks_order
            this.assignment_activity_sid = o.assignment_activity_sid
            this.reservation_activity_sid = o.reservation_activity_sid
            this.max_reserved_workers = o.max_reserved_workers
            this.target_workers = o.target_workers
            this.target_workers_json = o.target_workers_json
        }
    }

    public get Sid(): string {
        return this.sid
    }

    public get Name(): string {
        return this.friendly_name
    }

    public set Name(v: string) {
        this.friendly_name = v;
    }

    public get Order(): string {
        return this.tasks_order
    }

    public set Order(v: string) {
        this.tasks_order = v;
    }

    public get Assignment(): string {
        return this.assignment_activity_sid
    }

    public set Assignment(v: string) {
        this.assignment_activity_sid = v;
    }

    public get Reservation(): string {
        return this.reservation_activity_sid
    }

    public set Reservation(v: string) {
        this.reservation_activity_sid = v;
    }

    public get MaxReserved(): number {
        return this.max_reserved_workers
    }

    public set MaxReserved(v: number) {
        this.max_reserved_workers = v;
    }

    public get Expression(): string {
        return this.target_workers_json
    }

    public set Expression(v: string) {
        this.target_workers_json = v;
    }

    public get TargetWorkers(): string {
        return this.target_workers
    }

    public set TargetWorkers(v: string) {
        this.target_workers = v;
    }

} 