import { SpamFilter } from "../models/SpamFilter/SpamFilter";
import Api from "../config/api";

export class SpamFilterActions {
    static get(): Promise<SpamFilter[]> {
        return Api.get(`/spam-filter`)
            .then((res: any) => { return res.data })
    }
    static getById(id:number): Promise<SpamFilter> {
        return Api.get(`/spam-filter/${id}`)
            .then((res: any) => { return res.data })
    }
    static create(filter:SpamFilter): Promise<SpamFilter> {
        return Api.post(`/spam-filter`, filter)
            .then((res: any) => { return res.data })
    }
    static update(id:number,filter:SpamFilter): Promise<SpamFilter> {
        return Api.put(`/spam-filter/${id}`, filter)
            .then((res: any) => { return res.data })
    }
    static delete(id:number): Promise<SpamFilter> {
        return Api.delete(`/spam-filter/${id}`)
            .then((res: any) => { return res.data })
    }
    static toggleActivity(id:number, active:boolean): Promise<SpamFilter> {
        return Api.put(`/spam-filter/toggle-activity/${id}`,{ active: active })
            .then((res: any) => { return res.data })
    }
    static getBlockVoip(): Promise<any> {
        return Api.get(`/voip-spam-block`)
            .then((res: any) => { return res.data })
            
    }
    static blockVoip(interval:number): Promise<any> {
        return Api.post(`/voip-spam-block`, {interval:interval})
            .then((res: any) => { return res.data })
            
    }
    static unblockBlockVoip(): Promise<SpamFilter> {
        return Api.delete(`/voip-spam-block`)
            .then((res: any) => { return res.data })
    }
}