import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { BlueBtn, RedInverseBtn } from '../Styled/StyledButtons';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import React from 'react';
import styles from './styles.module.css'
import { Team } from '../../models/Teams/Team';
import { TeamsActions } from '../../actions/TeamsActions';

interface IProps {
    team: Team,
    editShow: boolean,
    toggleHandle: () => void,
    savedHandle: () => void
}
interface IState {
    team: Team
}
export default class TeamEditComponent extends React.Component<IProps, IState> {

    state: IState = {
        team: this.props.team
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.team !== prevProps.team) {
            this.setState({ team: this.props.team })
        }
    }

    handleName(value: string) {
        let team = this.state.team
        team.Name = value
        this.setState({ team: team })
    }

    load(id: number) {
        TeamsActions.getTeam(id)
            .then(team => { this.setState({ team: team }) })
    }

    save() {
        if (this.state.team.Name.length > 0) {
            if (this.state.team.Id > 0) {
                TeamsActions.updateTeam(this.state.team.Id, this.state.team)
                    .then(team => { this.props.savedHandle() })
            } else {
                TeamsActions.createTeam(this.state.team)
                    .then(team => { this.props.savedHandle() })

            }
        }
    }

    render() {
        return (
            <Dialog open={this.props.editShow} onClose={this.props.toggleHandle}>
                <DialogTitle>
                    {this.state.team.Id === null ? 'Add new team' : 'Edit team "' + this.state.team.Name + '"'}
                    <IconButton className={mainStyles.floatRight} onClick={this.props.toggleHandle}>
                        <ClearIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={styles.popup}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                        <label className={styles.FormLabel}>Team Name</label>
                        <input placeholder="Team Name" className={`${(this.state.team.Name === '') ? styles.FormInvalid : ""} ${styles.FormControl} `} value={this.state.team.Name} onChange={(event) => this.handleName(event.target.value)} />
                            
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1} >
                        <Grid item xs={6}  >
                            <RedInverseBtn fullWidth={true} onClick={this.props.toggleHandle}>Cancel</RedInverseBtn>
                        </Grid>
                        <Grid item xs={6}  >
                            <BlueBtn fullWidth={true} onClick={() => this.save()}>Save</BlueBtn>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }
}