import { GUID } from "../Guid"

export interface IRoutingStep {
    id: number
    friendly_name: string
    queue_sid: string
    queue_name: string
    priority: number
    position: number
    timeout: number
    skip_timeout: string
    skip_timeout_json: string
    ordering: string
    ordering_json: string
    expression: string
    expression_json: string    
    task_expression: string
    task_expression_json: string   
}

export class RoutingStep {
    private id: number = 0
    private friendly_name: string = ''
    private guid: string = GUID.New()
    private queue_sid: string = ''
    private queue_name: string = ''
    private priority: number = 0
    private position: number = 0
    private timeout: number = 60
    private skip_timeout:string = ''
    private skip_timeout_json:string = ''
    private expression: string = ''
    private expression_json: string = ''
    private task_expression: string = ''
    private task_expression_json: string = ''
    private ordering: string = ''
    private ordering_json: string = ''
    constructor(o: IRoutingStep | undefined) {
        if (o !== undefined) {
            this.id = o.id
            this.friendly_name = o.friendly_name
            this.queue_sid = o.queue_sid
            this.priority = o.priority
            this.position = o.position
            this.timeout = o.timeout
            this.skip_timeout = o.skip_timeout
            this.skip_timeout_json = o.skip_timeout_json
            this.expression = o.expression
            this.expression_json = o.expression_json
            this.task_expression = o.task_expression
            this.task_expression_json = o.task_expression_json
            this.ordering = o.ordering
            this.ordering_json = o.ordering_json
        }
    }

    public get Id(): number {
        return this.id
    }
    public get Name(): string {
        return this.friendly_name
    }
    public get Guid(): string {
        return this.guid
    }

    public get QueueSid(): string {
        return this.queue_sid
    }

    public get QueueName(): string {
        return this.queue_name
    }

    public get Priority(): number {
        return this.priority
    }

    public get Position(): number {
        return this.position
    }

    public get Timeout(): number {
        return this.timeout
    }

    public get SkipTimeout(): string {
        return this.skip_timeout
    }
    public get SkipTimeoutJson(): string {
        return this.skip_timeout_json
    }

    public get Expression(): string {
        return this.expression
    }
    public get ExpressionJson(): string {
        return this.expression_json
    }

    public get TaskExpression(): string {
        return this.task_expression
    }
    public get TaskExpressionJson(): string {
        return this.task_expression_json
    }
    
    public get Ordering(): string {
        return this.ordering
    }
    public get OrderingJson(): string {
        return this.ordering_json
    }

    public set Name(v: string) {
        this.friendly_name = v;
    }
    
    public set QueueSid(v: string) {
        this.queue_sid = v;
    }
    public set QueueName(v: string) {
        this.queue_name = v;
    }    

    public set Priority(v: number) {
        this.priority = v;
    }

    public set Position(v: number) {
        this.position = v;
    }

    public set Timeout(v: number) {
        this.timeout = v;
    }

    public set Expression(v: string) {
        this.expression = v;
    }
    public set ExpressionJson(v: string) {
        this.expression_json = v;
    }

    public set TaskExpression(v: string) {
        this.task_expression = v;
    }
    public set TaskExpressionJson(v: string) {
        this.task_expression_json = v;
    }

    public set SkipTimeout(v: string) {
        this.skip_timeout = v;
    }
    public set SkipTimeoutJson(v: string) {
        this.skip_timeout_json = v;
    }
    public set Ordering(v: string) {
        this.ordering = v;
    }
    public set OrderingJson(v: string) {
        this.ordering_json = v;
    }

}