import axios from "axios";
export const ApiUrl: string = (process.env.REACT_APP_CCS_API_URL !== undefined) ? process.env.REACT_APP_CCS_API_URL :'https://staging-active-calls.apollosoft.co'

const path = '/admin'

let token = localStorage.getItem('token')
let headers

if(token !== null){
  headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+ token
  }
}else{
  headers = { 'Content-Type': 'application/json' }
}

export default axios.create({
    baseURL: ApiUrl+path,
    responseType: "json",
    headers: headers
  });