
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { BlueBtn, RedInverseBtn } from '../Styled/StyledButtons';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import React from 'react';
import styles from './styles.module.css'
import { SpamFilter } from '../../models/SpamFilter/SpamFilter';
import { SpamFilterActions } from '../../actions/SpamFilterActions';

interface IProps {
    filter: SpamFilter,
    editShow: boolean,
    toggleHandle: () => void,
    savedHandle: () => void
}
interface IState {
    filter: SpamFilter
}
export default class SpamFilterEditComponent extends React.Component<IProps, IState> {

    state: IState = {
        filter: this.props.filter
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.filter !== prevProps.filter) {
            this.setState({ filter: this.props.filter })
        }
    }

    handleName(value: string) {
        let filter = this.state.filter
        filter.name = value
        this.setState({ filter: filter })
    }

    handleRegEx(value: string) {
        let filter = this.state.filter
        filter.regex = value
        this.setState({ filter: filter })
    }
    handleOrder(value: number){
        let filter = this.state.filter
        filter.order = value
        this.setState({ filter: filter })
    }
    load(id: number) {
        SpamFilterActions.getById(id)
            .then(filter => { this.setState({ filter: filter }) })
    }

    regExValid(){
        try {
            (new RegExp(this.state.filter.regex)).test('')
        } catch(e) {
            return false
        }
        console.log('true')
        return true
    }

    save() {
        const filter = this.state.filter
        if (filter.name.length > 0 && this.regExValid()) {
            if (filter && filter.id && filter.id > 0) {
                SpamFilterActions.update(filter.id, filter)
                    .then(filter => { this.props.savedHandle() })
            } else {
                SpamFilterActions.create(this.state.filter)
                    .then(filter => { this.props.savedHandle() })
            }
        }
    }

    render() {
        const validRegExp = this.regExValid()
        return (
            <Dialog open={this.props.editShow} onClose={this.props.toggleHandle}>
                <DialogTitle>
                    {this.state.filter.id === null ? 'Add new filter' : 'Edit filter "' + this.state.filter.name + '"'}
                    <IconButton className={mainStyles.floatRight} onClick={this.props.toggleHandle}>
                        <ClearIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={styles.popup}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                        <label className={styles.FormLabel}>Filter Name</label>
                        <input placeholder="Filter Name" className={`${(this.state.filter.name === '') ? styles.FormInvalid : ""} ${styles.FormControl} `} value={this.state.filter.name} onChange={(event) => this.handleName(event.target.value)} />
                        <label className={styles.FormLabel}>Order</label>
                        <select className={`${styles.FormControl} ${styles.FullWidth}`} value={this.state.filter.order} onChange={event => this.handleOrder(parseInt(event.target.value))} >
                    
                    <option value="1">Allow</option>
                    <option value="0">Deny</option>
                  </select>
                        <label className={styles.FormLabel}>Filter RegExp</label>
                        <input placeholder="Filter RegExp" className={`${ !validRegExp ? styles.FormInvalid : ""} ${styles.FormControl} `} value={this.state.filter.regex} onChange={(event) => this.handleRegEx(event.target.value)} />
                            
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1} >
                        <Grid item xs={6}  >
                            <RedInverseBtn fullWidth={true} onClick={this.props.toggleHandle}>Cancel</RedInverseBtn>
                        </Grid>
                        <Grid item xs={6}  >
                            <BlueBtn fullWidth={true} onClick={() => this.save()}>Save</BlueBtn>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }
}