import React from 'react';

export interface IProps{
  config:string,
  data: string,
  dataOut: (data:string)=>void
}
/// *******Need for RegExp Component********
interface QueryBuilderProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  config: string,
  data: string
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'query-builder-lib': QueryBuilderProps;
    }
  }
}
/// *****************************************

export default class ExpressionComponent extends React.Component<IProps> {

  nv:any 

  constructor(props: IProps){
    super(props)
    this.handleDataOut = this.handleDataOut.bind(this)
  }

  handleNv(elem:HTMLElement | null){
    if(this.nv === undefined && elem !== null){
      this.nv = elem
      this.nv.addEventListener("dataOut", this.handleDataOut);
      this.componentWillUnmount = ()=>{this.nv.removeEventListener("dataOut", this.handleDataOut);}
    }
  }

  handleDataOut(data: any){    
    if(data!== undefined && data.detail !== undefined){
      const exp = data.detail
      this.props.dataOut(JSON.stringify(exp)) 
    }    
  }

  render () { 
  return (
  <div>
    <query-builder-lib ref={elem => this.handleNv(elem)} config={this.props.config} data={this.props.data} ></query-builder-lib>
  </div>
  )
  }
}