import React from 'react';
import { LocationBrandsActions } from '../../actions/LocationBrandsActions';

interface IState {
    selectedFile?: File
}

interface IProps {
    onUpload: (fileUrl: string) => void
}

export default class UploadFiles extends React.Component<IProps, IState>{
    state: IState = {
        selectedFile: undefined
    }
    selectFile(files: FileList) {
        if (files.length > 0)
            this.setState({ selectedFile: files[0] })
    }
    upload() {
        const selectedFile = this.state.selectedFile
        if (selectedFile) {
            LocationBrandsActions.uploadAudio(selectedFile)
                .then(resp => {
                    if(resp.url)
                    this.props.onUpload(resp.url)
                    this.setState({ selectedFile: undefined });
                })
        }

    }
    render() {
        const selectedFile = this.state.selectedFile

        return (
            <div>
                <label className="btn btn-default">
                    <input type="file" onChange={(e) => { if (e.target.files) this.selectFile(e.target.files) }} />
                </label>

                <button className="btn btn-success"
                    disabled={selectedFile === undefined}
                    onClick={() => this.upload()}
                >
                    Upload
                </button>
            </div>
        );
    }
}