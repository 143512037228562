import { Expression, ExpressionRule } from "./ExpressionBuilderTypes";

export class ExpressionValidator{
    public static Validate(expression: Expression){
        let errors: string[] = []
        expression.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                let childrenErrors: string[] = []
                childrenErrors = this.validateRule(item)
                if (childrenErrors.length>0) {
                    errors = [...errors, ...childrenErrors]
                }
            }
            if (item.condition !== undefined) {
                let childrenErrors: string[] = []
                childrenErrors = this.validateExpression(item)
                if (childrenErrors.length>0) {
                    errors = [...errors, ...childrenErrors]
                }
            }
        });
        return errors
    }
    private static validateRule(element: ExpressionRule) {
        let errors: string[] = []
        if(element.operator === undefined || element.operator === null){
            errors.push(element.field +" operator not selected")
        }
        if((element.value === undefined || element.operator === null || element.value === 'undefined') 
        && (element.field !== 'exist_customer' && element.field !== 'is_member')){
            errors.push(element.field +" value can`t be undefined")
        }
        return errors
    }

    private static validateExpression(element: Expression) {
        let errors: string[] = []

        if (element.rules.length === 0) {
            return errors
        }

        element.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                let childrenErrors: string[] = []
                childrenErrors = this.validateRule(item)
                if (childrenErrors.length>0) {
                    errors = [...errors, ...childrenErrors]
                }
            }
            if (item.condition !== undefined) {
                let childrenErrors: string[] = []
                childrenErrors = this.validateExpression(item)
                if (childrenErrors.length>0) {
                    errors = [...errors, ...childrenErrors]
                }
            }
        });
        return errors
    }
    
}