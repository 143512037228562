import React from 'react';
import { TaskChannel } from '../../models/AgentSkills/TaskChannel';
import { TaskChannelChanges } from '../../models/AgentSkills/TaskChannelsChanges';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';

export interface IProps {
  taskChannels: TaskChannel[],
  agentTaskChannels: TaskChannelChanges[],
  handleChangeAvailable: (id: string, available: boolean) => void
  handleChangeCapacity: (id: string, capacity: number) => void
}


export default class TaskChannelsComponent extends React.Component<IProps> {

  changeInput(id: string, value: string) {
    let capacity = parseInt(value)
    if (capacity > 0 && capacity <= 50) {
      this.props.handleChangeCapacity(id, capacity)
    }
  }

  getAgentChannels(sid: string): TaskChannelChanges {
    for (let i = 0; i < this.props.agentTaskChannels.length; i++) {
      if (this.props.agentTaskChannels[i].Sid === sid) {
        return this.props.agentTaskChannels[i]
      }
    }
    return new TaskChannelChanges()
  }

  valid() {
    for (let i = 0; i < this.props.agentTaskChannels.length; i++) {
      if (this.props.agentTaskChannels[i].Available) {
        return true
      }
    }
    return false
  }
  isAllChecked(){
    let isAll = true
    this.props.agentTaskChannels.forEach((taskChannel)=>{
      if(!taskChannel.Available){
        isAll = false
      }
    })
    return isAll;
  }
  handleCheckedAll(){
    let checked = true
    if(this.isAllChecked()){
      checked = false
    }
    this.props.agentTaskChannels.forEach(taskChannel => {
      this.props.handleChangeAvailable(taskChannel.Sid, checked)
    });
  }

  render() {
    let error = <div></div>
    if (!this.valid()) { error = <div className={styles.Error}>Select one or more items</div> }
    return <div>
      <p className={mainStyles.ComponentHeader}><span >task channels</span></p>
      <div className={`${mainStyles.ComponentBody} ${(!this.valid()) ? styles.ComponentInvalid : ''} `} >
        {error}
        <table className={styles.Table}>
          <thead>
            <tr>
              <th className={styles.BlackUnderlined}>
                <label className={styles.SkillsCheckbox}>
                  <input type="checkbox" value="all" checked={this.isAllChecked()} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCheckedAll()} />
                  <span className={`${styles.CheckLabelChecked}`}>NAME</span>
                </label>
              </th>
              <th className={`${styles.ValueCol} ${styles.BlackUnderlined}`}><label className={styles.CheckLabelChecked}>VALUE</label></th>
            </tr>
          </thead>
          <tbody>
            {this.props.taskChannels.map((row, index) => (
              <tr key={index} className={`${((this.getAgentChannels(row.Sid).Available) ? styles.CheckLabelChecked : styles.CheckLabel)} ${styles.Underlined}`}> 
                <td className={styles.Underlined}>
                  <label className={styles.SkillsCheckbox} key={row.Sid} >
                    <input type="checkbox" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleChangeAvailable(event.target.value, event.target.checked)} checked={this.getAgentChannels(row.Sid).Available} value={row.Sid} />
                    <span>{row.Name}</span>
                  </label>
                </td>
                <td className={`${styles.ValueCol} ${styles.Underlined}`}>
                  <input type="number" className={`${styles.FormControl}  ${styles.FullWidth}`} disabled={!this.getAgentChannels(row.Sid).Available} min="0" max="50" onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.changeInput(event.target.name, event.target.value)} name={row.Sid} value={this.getAgentChannels(row.Sid).Capacity} />
                </td>

              </tr>
            ))}
          </tbody>
        </table>        
      </div>
    </div>
  }
}
