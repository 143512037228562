import { responsiveFontSizes } from '@material-ui/core';
import React from 'react';
import { AgentSkillsActions } from '../../../actions/AgentSkillsActions';
import { AgentCustomKPI } from '../../../models/AgentSkills/AgentCustomKPI';
import { CustomKPI } from '../../../models/AgentSkills/CustomKPI';
import mainStyles from '../../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';

export interface IProps {
    agentCustomKPIs: AgentCustomKPI[]
    handleChange: (kpis: AgentCustomKPI[]) => void
}

interface IState {
    kpis: CustomKPI[]
}

export default class CustomKPIsComponent extends React.Component<IProps, IState> {

    state: IState = {
        kpis: []
    }

    componentDidMount() {
        AgentSkillsActions.getCustomKPIs()
            .then((resp: any) => { this.setState({ kpis: resp.data }) })
    }

    handleChangeValue(id: number, value: string) {
        const index = this.getOrNewAgentCustomKPI(id)
        const agentCustomKPIs = this.props.agentCustomKPIs
        agentCustomKPIs[index].value = value

        this.props.handleChange(agentCustomKPIs)
    }

    handleChangePercents(id: number, percents: string) {
        const index = this.getOrNewAgentCustomKPI(id)
        const agentCustomKPIs = this.props.agentCustomKPIs
        agentCustomKPIs[index].percents = percents

        this.props.handleChange(agentCustomKPIs)
    }

    getOrNewAgentCustomKPI(custom_kpi_id: number): number {
        const agentCustomKPIs = this.props.agentCustomKPIs
        for (var i = 0; i < agentCustomKPIs.length; i++) {
            if (custom_kpi_id === agentCustomKPIs[i].custom_kpi_id) {
                return i
            }
        }
        const agentCustomKPI = new AgentCustomKPI(custom_kpi_id)
        agentCustomKPIs.push(agentCustomKPI)
        this.props.handleChange(agentCustomKPIs)

        return agentCustomKPIs.length - 1
    }

    render() {
        let error = <div></div>
        const agentCustomKPIs = this.props.agentCustomKPIs
        return (
            <div>
                <div className={mainStyles.ComponentHeader}><span >Custom KPIs</span></div>
                <div className={`${mainStyles.ComponentBody} ${mainStyles.Scrollable}`} >
                    {error}
                    {this.state.kpis.map((item: CustomKPI) => (
                        <label key={item.id} className={styles.CheckLabel}>
                            <span>{item.name} {item.custom_kpi_type_id===1?'(Weekly)':'(Monthly)'}</span><br />
                            <input className={`${styles.FormControl} ${styles.width50}`} value={agentCustomKPIs[this.getOrNewAgentCustomKPI(item.id)].value} placeholder="value"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleChangeValue(item.id, event.target.value)} />
                            <input className={`${styles.FormControl} ${styles.width50}`} value={agentCustomKPIs[this.getOrNewAgentCustomKPI(item.id)].percents} placeholder="percents"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleChangePercents(item.id, event.target.value)} />
                        </label>
                    ))}
                </div>
            </div>
        )
    }
}
