import Api from "./../config/api";
import { IWorkspace, Workspace } from "../models/Workspaces/Workspace";
import { Activity, IActivity } from "../models/Workspaces/Activity";
import { ITaskChannel, TaskChannel } from "../models/Workspaces/TaskChannel";

interface IAxiosWorkspaces {
    data: IWorkspace[]
}

interface IAxiosActivities {
    data: IActivity[]
}

interface IAxiosTaskChannels {
    data: ITaskChannel[]
}

export class WorkspacesActions {

    static getWorkspaces(): Promise<Workspace[]> {
        return Api.get("/workspaces")
            .then((res: IAxiosWorkspaces): Workspace[] => { return res.data.map((d: IWorkspace): Workspace => { return new Workspace(d) }) })
    }
    static getActivities(workspaceId:string): Promise<Activity[]> {
        return Api.get(`/workspaces/${workspaceId}/activities`)
            .then((res: IAxiosActivities): Activity[] => { return res.data.map((d: IActivity): Activity => { return new Activity(d) }) })
    }
    static getTaskChannels(workspaceId:string): Promise<TaskChannel[]> {
        return Api.get(`/workspaces/${workspaceId}/task-channels`)
            .then((res: IAxiosTaskChannels): TaskChannel[] => { return res.data.map((d: ITaskChannel): TaskChannel => { return new TaskChannel(d) }) })
    }
}