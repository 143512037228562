import { Container, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress } from '@material-ui/core';
import { Add, DeleteForever } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import { BlueInverseBtn } from '../Styled/StyledButtons';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import React from 'react';
import styles from './styles.module.css'
import { Team } from '../../models/Teams/Team';
import { TeamsActions } from '../../actions/TeamsActions';
import TeamEditComponent from './TeamEditComponent';

interface IState {
    teams: Team[],
    editShow: boolean,
    currentTeam: Team,
    backdrop: boolean,
}
export default class TeamsManagementComponent extends React.Component {

    state: IState = {
        teams: [ ],
        editShow: false,
        currentTeam: new Team(undefined),
        backdrop: true,
    }
    componentDidMount(){
        document.title = 'Teams'
        this.load()
    }

    load() {
        TeamsActions.getTeams()
            .then(teams => { this.setState({ teams: teams, backdrop: false }) })
    }
    delete(id: number | null) {
        if( typeof id === 'number')
        TeamsActions.deleteTeam(id)
            .then(() => this.load())
    }

    toggleEdit(){
        this.setState({editShow: !this.state.editShow})
    }

    savedHandle(){
        this.load()
        this.setState({editShow: false})
    }

    addTeam(){
        this.setState({currentTeam: new Team(undefined), editShow: true})        
    }
    editTeam(team: Team){
        this.setState({currentTeam: team, editShow: true})
    }
    render() {
        return (
            <div>
                <Container maxWidth="lg">
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className={mainStyles.Header}>Teams Management</div>
                                    <div className={mainStyles.AgentName}> &nbsp;</div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}><BlueInverseBtn onClick={()=>this.addTeam()} variant="contained" fullWidth={true}><Add /> Add Team</BlueInverseBtn></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={mainStyles.FlexColScroll}>
                                        {this.state.teams.map((row, index) => (
                                            <TableRow key={row.Id}>
                                                <TableCell align="left">{row.Name}</TableCell>
                                                <TableCell align="right">
                                                    <Button color="primary" onClick={()=>this.editTeam(row)}><CreateIcon /></Button>
                                                    <Button color="secondary" onClick={() => { this.delete(row.Id) }}><DeleteForever /></Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>
                <TeamEditComponent team={this.state.currentTeam} editShow={this.state.editShow} toggleHandle={() => this.toggleEdit()} savedHandle={() => this.savedHandle()}/>
                <Backdrop className={styles.Backdrop} open={this.state.backdrop}>
                    <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
          <p className={styles.BackdropProgress}>Loading...</p>
                </Backdrop>
            </div>
        )
    }
}