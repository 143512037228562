import Api from "../config/api";
import { ITeam, Team } from "../models/Teams/Team";

interface IAxiosTeams{
    data: ITeam[]
}

interface IAxiosTeam{
    data: ITeam
}

export class TeamsActions{ 
    static getTeams(): Promise<Team[]>{
        return Api.get("/teams")
        .then((res:IAxiosTeams):Team[]=> {return res.data.map((d:ITeam):Team => { return new Team(d)})})
    }
    static getTeam(id: number): Promise<Team>{
        return Api.get("teams/"+id)
        .then((res:IAxiosTeam):Team=>{return new Team(res.data)})
    }
    static createTeam(team: Team): Promise<Team>{
        return Api.post("/teams", team)
        .then((res:IAxiosTeam):Team=> {return new Team(res.data)})        
    }
    static updateTeam(id: number, team: Team): Promise<Team>{
        return Api.put("/teams/"+id, team)
        .then((res:IAxiosTeam):Team=> {return new Team(res.data)})        
    }
    static deleteTeam(id: number):Promise<Team> {
        return Api.delete("/teams/"+id)
        .then((res:IAxiosTeam):Team=> {return new Team(res.data)})
    }
    

}