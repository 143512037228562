import Api from "./../config/api";
import { Department, IDepartment } from "../models/AgentSkills/Department";

interface IAxiosDepartments{
    data: IDepartment[]
  }

export class DepartmentsActions{
        static getDepartments():Promise<Department[]>{
        return Api.get("/departments")
        .then((res:IAxiosDepartments):Department[]=> {return res.data.map((d:IDepartment):Department => { return new Department(d)})})
    }
}