import React from 'react';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css'
import { Dialog, DialogTitle, DialogActions, DialogContent, Grid, IconButton, Container } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { CustomSkill } from '../../models/AgentSkills/CustomSkill';
import { AgentSkillsActions } from '../../actions/AgentSkillsActions';
import { RedInverseBtn, BlueBtn } from '../Styled/StyledButtons';
import { CustomSkillsTypes } from '../../models/AgentSkills/CustomSkillsTypes';

export interface IProps {
  open: boolean
  toggle: () => void,
  addNewSkills: () => void
}

interface IState {
  newSkill: CustomSkill,
  errors: string[],
}

export default class AddCustomSkillsComponent extends React.Component<IProps, IState> {

  state: IState = {
    newSkill: new CustomSkill(undefined),
    errors: [],
  }

  handleKey(key: string) {
    const re = /[0-9a-zA-Z_]$|^$/g;
    if (re.test(key)) {
      let o = this.state.newSkill
      o.Key = key
      this.setState({ newSkill: o, errors: [] })
    }
  }

  save() {
    if (this.validate()) {
      AgentSkillsActions.storeSkill(this.state.newSkill).then((data) => {
        if (data instanceof CustomSkill) {
          this.setState({ newSkill: new CustomSkill(undefined) })
          this.props.addNewSkills()
        } else {
          this.setState({ errors: data.errors })
        }

      })
    }
  }
  validate() {
    if (this.state.newSkill.Key.length === 0) {
      return false
    } else {
      const re = /[a-zA-Z_]$/g;
      const msg = "Must have one or more letters"
      let errors = this.state.errors

      if (!re.test(this.state.newSkill.Key)) {
        if (errors.indexOf(msg) < 0) {
          errors.push(msg)
          this.setState({ errors: errors })
        }
        return false
      } else {
        if (errors.indexOf(msg) >= 0) {
          errors.splice(errors.indexOf(msg), 1)
          this.setState({ errors: errors })
        }
      }
    }


    return true
  }

  handleTypeChange(typeId: number){
    const o = this.state.newSkill
    o.TypeId = typeId
    this.setState({ newSkill: o })
  }

  render() {
    return (<div >
      <Dialog open={this.props.open} onClose={this.props.toggle}>
        <DialogTitle className={styles.PopUpHeader}>
          Add Custom Skill
          <IconButton className={mainStyles.floatRight} onClick={this.props.toggle}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Container maxWidth={false}>
            <Grid container>
              <Grid item xs={12}>
                <div className={styles.SkillsMargin}>
                  <label className={styles.FormLabel}>Key</label>
                  <input placeholder="Key" className={`${(this.state.newSkill.Key === '') ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} value={this.state.newSkill.Key} onChange={(e) => { this.handleKey(e.target.value) }} />
                  <label className={styles.FormLabel}>Type</label>
                  <select className={`${(this.state.newSkill.TypeId <= 0)? styles.FormInvalid : ""} ${styles.FormControl}  ${styles.FullWidth}`} value={this.state.newSkill.TypeId} onChange={event => this.handleTypeChange(parseInt(event.target.value))}>
                                                    <option value="-1" disabled >Chose value type</option>
                                                    {Object.keys(CustomSkillsTypes).map((key) => (
                                                        <option key={key} value={key} >{CustomSkillsTypes[parseInt(key)]}</option>
                                                    ))}
                                                </select>
                  <div className={styles.CustomSkillError}>{this.state.errors}</div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions>
          <Container maxWidth={false}>
            <div style={{ marginBottom: '24px' }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <RedInverseBtn fullWidth={true} onClick={this.props.toggle}>Cancel</RedInverseBtn>
                </Grid>
                <Grid item xs={6}>
                  <BlueBtn fullWidth={true} onClick={() => this.save()} >Save</BlueBtn>
                </Grid>
              </Grid>
            </div>
          </Container>
        </DialogActions>
      </Dialog>

    </div>
    )
  }
}

