import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';
import { Backdrop, CircularProgress, Container, Grid, Snackbar, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { BlueInverseBtn, RedInverseBtn, BlueBtn } from '../Styled/StyledButtons';

import { Workflow } from '../../models/Workflows/Workflows';
import { Queue } from '../../models/Queue/Queue';
import { WorkspacesActions } from '../../actions/WokspacesActions';
import { Workspace } from '../../models/Workspaces/Workspace';
import { QueuesManagementActions } from '../../actions/QueuesManagementActions';
import { DepartmentsActions } from '../../actions/DepartmentsActions';
import { ExpressionOption } from '../../models/ExpressionBuilder/ExpressionBuilderTypes';
import { Department } from '../../models/AgentSkills/Department';
import { WorkflowsManagementActions } from '../../actions/WorkflowsManagmentActions';
import ConfirmComponent from '../ConfirmComponent/ConfirmComponent';
import WorkflowTriggersComponent from './WorkflowTriggersComponent';
import { Trigger } from '../../models/Workflows/Trigger';
import { Alert } from '@material-ui/lab';
import { IFilter } from '../../models/Workflows/Filter';
import { GUID } from '../../models/Guid';
import WorkflowFilterComponent from './Filter/Filter';

interface IState {
  discardConfirmShow: boolean,
  currentWorkspace: string,
  workspaces: Workspace[],
  workflow: Workflow,
  queues: Queue[],
  showExpression: boolean,
  config: any,
  departments: Department[],
  backdrop: boolean,
  alert: boolean,
  errorAlert: boolean,
  alertErrors: string[],
  errors: string[],
  saving: boolean
}
export default class WorkflowEditComponent extends React.Component<any, IState> {
  state: IState = {
    discardConfirmShow: false,
    workspaces: [],
    currentWorkspace: '',
    workflow: new Workflow(undefined),
    queues: [],
    showExpression: false,
    departments: [],
    backdrop: (this.props.match.params.sid !== undefined),
    config: {
      fields: {
        departments: { name: 'Departments', type: 'category', options: [], operators: ['='] },
        task_for: { name: 'Activities', type: 'category', options: [{ name: 'Booking', value: 'booking' }, { name: 'Complains', value: 'complains' }, { name: 'Support', value: 'support' }, { name: 'Membership', value: 'membership' }, { name: 'Dsr', value: 'dsr' }, { name: 'Reception', value: 'reception' }], operators: ['='] },
        is_member: { name: 'Is Member', type: 'boolean', operators: ['='] },
      }
    },

    alert: false,
    errorAlert: false,
    alertErrors: [],
    errors: [],
    saving: false
  }
  defaultData = {
    condition: 'and',
    rules: [
      { field: 'departments', operator: '=', value: 0 },
      { field: 'task_for', operator: '=', value: 0 },
      { field: 'is_member', operator: '=', value: false },
    ]
  }

  componentDidMount() {
    this.loadWorkspaces()
    this.loadDepartments()
  }

  loadDepartments() {
    DepartmentsActions.getDepartments()
      .then(departments => {
        let deps: ExpressionOption[] = []

        departments.forEach((item, index) => {
          deps.push(new ExpressionOption(item.Name, item.Id.toString()))
        })

        let config = this.state.config
        config.fields.departments.options = deps
        this.setState({
          departments: departments,
          config: config
        })
      }
      )
  }
  loadWorkspaces() {
    WorkspacesActions.getWorkspaces()
      .then(workspaces => {
        if (workspaces.length > 0) {
          this.setState({ workspaces: workspaces, currentWorkspace: workspaces[0].Sid })
          this.loadQueues()
          let sid = this.props.match.params.sid
          if (sid !== undefined) {
            this.loadWorkflow(sid)
          } else {
            document.title = 'Add new Workflow'
          }
        }
      })
  }
  loadWorkflow(workflowSid: string) {
    WorkflowsManagementActions.getWorkflow(this.state.currentWorkspace, workflowSid)
      .then((data) => {
        if (data instanceof Workflow) {
          document.title = data.FriendlyName + ' | Edit Workflow'
          this.setState({ workflow: data, backdrop: false })
        } else {
          let errors = (data.errors === undefined) ? [data.error] : data.errors
          this.setState({ errorAlert: true, alertErrors: errors })
        }
      })
  }
  loadQueues() {
    QueuesManagementActions.getQueues(this.state.currentWorkspace)
      .then(queues => { this.setState({ queues: queues }) })
  }
  saveWorkflow() {
    this.setState({ saving: true })
    if (this.state.workflow.Sid !== "") {
      WorkflowsManagementActions.updateWorkflow(this.state.currentWorkspace, this.state.workflow.Sid, this.state.workflow)
        .then((data) => {
          if (data instanceof Workflow) {
            this.setState({ saving: false })
            this.goBack()
          } else {
            let errors = (data.errors === undefined) ? [data.error] : data.errors
            this.setState({ errorAlert: true, alertErrors: errors, saving: false })
          }
        })
    } else {
      WorkflowsManagementActions.createWorkflow(this.state.currentWorkspace, this.state.workflow)
        .then((data) => {
          if (data instanceof Workflow) {
            this.setState({ saving: false })
            this.goBack()
          } else {
            let errors = (data.errors === undefined) ? [data.error] : data.errors
            this.setState({ errorAlert: true, alertErrors: errors, saving: false })
          }
        })
    }
  }

  goBack() {
    this.props.history.push('/workflows');
  }
  handleName(value: string) {
    let o = this.state.workflow
    o.FriendlyName = value
    this.setState({ workflow: o })
  }
  handleTaskReservationTimeout(value: number) {
    let o = this.state.workflow
    o.TaskReservationTimeout = value
    this.setState({ workflow: o })
  }
  handleDefaultQueue(value: string) {
    let o = this.state.workflow
    o.DefaultQueueSid = value
    this.setState({ workflow: o })
  }
  
  addNewFilter() {
    let workflow = this.state.workflow
    const newFilter: IFilter = { friendly_name: '', guid: GUID.New(), expression: '', expression_json: '', routing_steps: [] }
    workflow.AddFilter(newFilter)
    this.setState({ workflow: workflow })
  }
  handleDeleteFilter(filter: IFilter) {
    let workflow = this.state.workflow
    workflow.RemoveFilter(filter)
    this.setState({ workflow: workflow })
  }
  handleChangeFilter(filter: IFilter) {
    let workflow = this.state.workflow
    workflow.SetFilter(filter)
    this.setState({ workflow: workflow })
  }
  handleTriggers(triggers: Trigger[]) {
    const o = this.state.workflow
    o.Triggers = triggers
    this.setState({ workflow: o })
  }

  reorder(list: IFilter[], startIndex: number, endIndex: number) {
    const result = list;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    const o = this.state.workflow

    o.Filters = this.reorder(
      this.state.workflow.Filters,
      result.source.index,
      result.destination.index
    )
    this.setState({ workflow: o });
  }
  closeDiscardConfirm() {
    this.setState({ discardConfirmShow: false })
  }
  showDiscardConfirm() {
    this.setState({ discardConfirmShow: true })
  }

  validate() {
    let valid: boolean = true
    valid = valid && (this.state.workflow.FriendlyName !== '')
    valid = valid && (this.state.workflow.DefaultQueueSid !== '')
     /*this.state.workflow.RoutingSteps.forEach((item) => {
      valid = valid && (item.QueueSid !== '')
      valid = valid && (item.Expression !== '')
      valid = valid && (item.TaskExpression !== '')
    })*/
    valid = valid && (this.state.workflow.Triggers.length > 0)
    return valid
  }
  handleClose() {
    this.setState({ alert: false })
  }
  handleCloseError() {
    this.setState({ errorAlert: false })
  }
  render() {
    return (
      <div>
        <Container maxWidth="md">
          <Grid container spacing={1}>
            <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={mainStyles.Header}>Workflows Management</div>
                  <div className={mainStyles.AgentName}> {(this.state.workflow.Sid === '') ? 'Add new Workflow' : this.state.workflow.FriendlyName}</div>
                </Grid>
              </Grid>
              <div className={styles.ComponentBody}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <label className={styles.StepFormLabel}>Name</label>
                    <input className={`${(this.state.workflow.FriendlyName === '') ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleName(event.target.value)} value={this.state.workflow.FriendlyName} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <label className={styles.StepFormLabel}>Default queue <Tooltip title='Match Workers within Default Queue if Task has passed through all filters. Will attempt to find a matching Worker until Task Timeout.' placement="bottom"><span className={styles.HelpDesc}> ? </span></Tooltip></label>
                    <select className={`${styles.FormControl} ${styles.FullWidth} ${(this.state.workflow.DefaultQueueSid === '') ? styles.FormInvalid : ""}`} value={this.state.workflow.DefaultQueueSid} onChange={event => this.handleDefaultQueue(event.target.value as string)}>
                      <option value="" disabled>(select Default Queue)</option>
                      {this.state.queues.map((row, index) => (
                        <option key={row.Sid} value={row.Sid} >{row.Name}</option>
                      ))}
                    </select>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <label className={styles.StepFormLabel}>Task Reservation Timeout</label>
                    <input className={`${(this.state.workflow.TaskReservationTimeout === 0) ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleTaskReservationTimeout(parseInt(event.target.value))} value={this.state.workflow.TaskReservationTimeout} />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <WorkflowTriggersComponent currentWorkspace={this.state.currentWorkspace} triggers={this.state.workflow.Triggers} changeHandle={(triggers) => this.handleTriggers(triggers)} />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}><div className={styles.SubHead}><span >Filters</span></div></Grid>
                  <DragDropContext onDragEnd={(result: any) => this.onDragEnd(result)}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div style={{ width: "100%" }}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {this.state.workflow.Filters.map((filter: IFilter, index: number) => (
                            <Draggable key={filter.guid} draggableId={filter.guid} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <WorkflowFilterComponent 
                                   filter={filter} 
                                   currentWorkspace={this.state.currentWorkspace} 
                                   queues={this.state.queues}
                                   handleDeleteFilter={() => this.handleDeleteFilter(filter)} 
                                   handleChangeFilter={()=>this.handleChangeFilter(filter)}
                                   />
                                  
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>
                <Grid container justify={'center'}>
                  <Grid item xs={12} md={6}>
                    <div className={styles.ButtonMargin}>
                      <BlueInverseBtn variant="contained" fullWidth={true} onClick={() => this.addNewFilter()}><Add /> add filter</BlueInverseBtn>
                    </div>
                  </Grid>
                </Grid>
                
              </div>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}><div className={styles.ButtonMargin}><RedInverseBtn fullWidth={true} onClick={() => this.showDiscardConfirm()}>Discard</RedInverseBtn></div></Grid>
                <Grid item xs={12} md={6} ><div className={styles.ButtonMargin}><BlueBtn variant="contained" fullWidth={true} onClick={() => this.saveWorkflow()}><CircularProgress className={` ${styles.SaveProgress} ${(this.state.saving) ? '' : styles.DisplayNone}`} />&nbsp;&nbsp;Save</BlueBtn></div></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <ConfirmComponent show={this.state.discardConfirmShow} title="Discard changes" bodyText="Are you sure you want to discard unsaved changes?" close={() => this.closeDiscardConfirm()} confirm={() => this.goBack()}></ConfirmComponent>
        <Snackbar open={this.state.errorAlert} autoHideDuration={5000} onClose={() => this.handleCloseError()} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Alert onClose={() => this.handleCloseError()} severity="error" variant="filled">
            Error<br />
            <div className={styles.AlertError}>{this.state.alertErrors}</div>
          </Alert>
        </Snackbar>
        <Backdrop className={styles.Backdrop} open={this.state.backdrop}>
          <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
          <p className={styles.BackdropProgress}>Loading...</p>
        </Backdrop>
      </div >
    )
  }
}

