export interface ITaskChannel{
    sid: string;
    name: string;
    capacity: number
    available: boolean
}
export class TaskChannel{
   private sid:string
   public get Sid() : string {
       return this.sid
   }
   
   private name: string    
   public get Name() : string {
       return this.name
   }

   private capacity: number
   public get Capacity() : number {
       return this.capacity
   }
   
   private available: boolean
   
   public get Available() : boolean {
       return this.available
   }
   

   constructor(d:ITaskChannel){
       this.sid = d.sid
       this.name = d.name
       this.capacity = d.capacity
       this.available = d.available
   }    
}