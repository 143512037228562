import React, { useEffect, useState } from "react"
import { SpamFilterActions } from "../../actions/SpamFilterActions"
import BlockingPanel from "./BlockingPanel"
import History from "./History"

export interface IHistory {
    created_at: string,
    created_by: string,
    interval: number,
    stopped_by: string,
    stopped_at: string
}

const BlockVoipCalls = () => {
    const [blocked, setBlocked] = useState(null)
    const [history, setHistory] = useState([])

    const refresh = () => {
        SpamFilterActions.getBlockVoip()
            .then((data) => {
                setBlocked(data.active)
                setHistory(data.history)
            })
    }

    useEffect(() => {
        refresh()
    }, []);

    return (
        <>
            <BlockingPanel activeBlock={blocked} changed={() => refresh()} />
            <History history={history} />
        </>
    )
}
export default BlockVoipCalls