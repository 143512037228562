export class SpamFilter{
    readonly id?: number = undefined
    name:string = ''
    regex:string = ''
    active:boolean = true
    order: number = 1

    constructor(name:string, regex:string){
        this.name = name
        this.regex = regex
    }
}