export interface IDeleteSkillApiError {
    message: string
    queues?: IErrorQueue[]
    workflows?: IErrorWorkflow[]
}

export default class DeleteSkillApiError {
    private message: string = ''
    public get Message(): string {
        return this.message
    }

    private queues: ErrorQueue[] = []
    public get Queues(): ErrorQueue[] {
        return this.queues
    }

    private workflows: ErrorWorkflow[] = []

    public get Workflows(): ErrorWorkflow[] {
        return this.workflows
    }

    constructor(o: IDeleteSkillApiError | undefined = undefined) {
        if (o !== undefined) {
            this.message = o.message
            if (o.queues !== undefined) {
                o.queues.forEach((queue) => {
                    this.queues.push(new ErrorQueue(queue))
                })
            }
            if (o.workflows !== undefined) {
                o.workflows.forEach((workflow) => {
                    this.workflows.push(new ErrorWorkflow(workflow))
                })
            }
        }
    }
}

export interface IErrorQueue {
    sid: string
    friendly_name: string
}
export class ErrorQueue {
    private sid: string = ''
    public get Sid(): string {
        return this.sid
    }

    private friendly_name = ''
    public get FriendlyName(): string {
        return this.friendly_name
    }
    constructor(o: IErrorQueue) {
        this.sid = o.sid
        this.friendly_name = o.friendly_name
    }
}

export interface IErrorWorkflow {
    sid: string
    friendly_name: string
    steps: string[]
}
export class ErrorWorkflow {
    private sid: string = ''
    public get Sid(): string {
        return this.sid
    }

    private friendly_name = ''
    public get FriendlyName(): string {
        return this.friendly_name
    }

    private steps: string[] = []
    public get Steps(): string[] {
        return this.steps
    }

    constructor(o: IErrorWorkflow) {
        this.sid = o.sid
        this.friendly_name = o.friendly_name
        this.steps = o.steps
    }

}