import React from 'react';
import { Grid, Container, Backdrop, CircularProgress, Snackbar, Button } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css'
import { Agent } from '../../models/AgentSkills/Agent';
import { CustomSkill } from '../../models/AgentSkills/CustomSkill';
import { Department } from '../../models/AgentSkills/Department';
import { AgentSkillsActions } from '../../actions/AgentSkillsActions';
import TaskChannelsComponent from './TaskChannelsComponent';
import { TaskChannel } from '../../models/AgentSkills/TaskChannel';
import { AgentChanges } from '../../models/AgentSkills/AgentChanges';
import { TaskChannelChanges } from '../../models/AgentSkills/TaskChannelsChanges';
import { AgentsMultyAplly } from '../../models/AgentSkills/AgentsMultyapply';
import { DepartmentsActions } from '../../actions/DepartmentsActions';
import { GreenBtn, GreyInverseBtn } from '../Styled/StyledButtons';
import CustomSkillsComponent from './CustomSkillsComponent';
import AccessTypeComponent from './AccessTypeComponent';
import DepartmentsComponent from './DepartmentsComponent';
import ActivitiesSkillsComponent from './ActivitiesSkillsComponent';
import AgentKPIComponent from './AgentKPIComponent';
import { TeamsActions } from '../../actions/TeamsActions';
import { Team } from '../../models/Teams/Team';
import ConfirmComponent from '../ConfirmComponent/ConfirmComponent';
import { DeleteForever } from '@material-ui/icons';
import CustomKPIsComponent from './CustomKPIsComponent/CustomKPIsComponent';
import { AgentCustomKPI } from '../../models/AgentSkills/AgentCustomKPI';

interface IState {
  agent: Agent,
  customKPIs: AgentCustomKPI[],
  customSkills: CustomSkill[],
  departments: Department[],
  taskChannels: TaskChannel[],
  showChannels: boolean,
  agentChanges: AgentChanges,
  taskChannelsChanges: TaskChannelChanges[],
  agents: AgentsMultyAplly,
  alert: boolean,
  backdrop: boolean,
  teams: Team[],
  errorAlert: boolean,
  errors: string[],
  saving: boolean,
  deleteConfirmShow: boolean,
  discardConfirmShow: boolean
}

export interface IProps {
  agent: Agent,
  customSkills: CustomSkill[],
  departments: Department[],
}

export default class AgentSkillsComponent extends React.Component<any, IState> {
  private agentId;

  constructor(props: IProps) {
    super(props)
    this.state = {
      agent: new Agent(undefined),
      customKPIs: [],
      customSkills: [],
      departments: [],
      taskChannels: [],
      taskChannelsChanges: [],
      showChannels: false,
      agentChanges: new AgentChanges(undefined),
      agents: new AgentsMultyAplly(),

      alert: false,
      errorAlert: false,
      errors: [],
      backdrop: true,
      teams: [],
      saving: false,

      deleteConfirmShow: false,
      discardConfirmShow: false,
    }
    this.loadSkills = this.loadSkills.bind(this)
    this.skillsReload = this.skillsReload.bind(this)
    this.loadAgent = this.loadAgent.bind(this)
    this.departmentsChangeHandle = this.departmentsChangeHandle.bind(this)
    this.skillsReload = this.skillsReload.bind(this)
    this.handleBool = this.handleBool.bind(this)
    this.multyAplly = this.multyAplly.bind(this)
    this.agentId = this.props.match.params.id
  }

  departments: Department[] = []

  loadAgent() {
    AgentSkillsActions.getAgent(this.agentId)
      .then(data => {
        if (data instanceof Agent) {
          document.title = data.FirstName + ' ' + data.LastName + ' | ' + ((data.IsNew) ? 'Add Agent' : 'Edit Agent skills');
          this.setState({ agent: data, backdrop: false })
          this.createAgentEdit()
        }
      })
  }
  updateAgent() {
    if (this.validate()) {
      if (this.state.taskChannelsChanges.length > 0) {
        this.saveChannels()
        this.saveCustomKPIs()
      }

      this.setState({ saving: true })
      AgentSkillsActions.updateAgent(this.agentId, this.state.agentChanges)
        .then(data => {
          if (data instanceof Agent) {
            this.setState({ agent: data, alert: true, saving: false }); this.loadChannels()
          } else {
            let errors = (data.errors === undefined) ? [data.error] : data.errors
            this.setState({ errors: errors, errorAlert: true, saving: false })
          }
        })
    }
  }

  componentDidMount() {
    this.loadSkills()
    this.loadDepartments()
    this.loadTeams()
    this.loadAgent()
    this.loadChannels()
    this.loadCustomKPIs()
  }

  loadSkills() {
    AgentSkillsActions.getSkills()
      .then(skills => this.setState({ customSkills: skills }))
  }

  loadDepartments() {
    DepartmentsActions.getDepartments()
      .then(dep => this.setState({ departments: dep }))
  }

  loadChannels() {
    AgentSkillsActions.getAgentChannels(this.agentId)
      .then((channels) => { this.setState({ showChannels: true }); this.setState({ taskChannels: channels }); this.createChannelsEdit() })
      .catch(() => { this.setState({ showChannels: false }) })
  }

  loadTeams() {
    TeamsActions.getTeams()
      .then(teams => {
        this.setState({ teams: [...this.state.teams, ...teams] })
      })
  }

  loadCustomKPIs() {
    AgentSkillsActions.getAgentCustomKPIs(this.agentId)
      .then(resp => this.setState({ customKPIs: resp.data }))
      .catch(error => { })
  }

  createAgentEdit() {
    let agentChanges = new AgentChanges(this.state.agent)
    agentChanges.Departments = this.state.agent.Departments
    agentChanges.Roles = this.state.agent.Roles
    this.setState({ agentChanges: agentChanges })
  }
  createChannelsEdit() {
    let editChannels: TaskChannelChanges[] =
      this.state.taskChannels.map((elem): TaskChannelChanges => {
        let edit = new TaskChannelChanges()
        edit.Sid = elem.Sid
        edit.Capacity = elem.Capacity
        edit.Available = elem.Available
        return edit
      })
    this.setState({ taskChannelsChanges: editChannels })
  }

  saveChannels() {
    this.state.taskChannelsChanges.forEach(element => {
      AgentSkillsActions.updateAgentChannels(this.agentId, element)
        .then(() => { })
    });
  }

  saveCustomKPIs() {
    AgentSkillsActions.storeCustomKPIs(this.agentId, this.state.customKPIs)
      .then(resp => this.setState({ customKPIs: resp.data }))
      .catch(error => { })
  }

  skillsReload() {
    this.loadSkills()
  }
  customSkillsAvailabilityChangeHandle(id: number, available: boolean) {
    if (available) {
      if (this.state.agentChanges.FindSkill(id) < 0) {
        this.state.agentChanges.AddSkill(id, '')
      }
    } else {
      this.state.agentChanges.RemoveSkill(id)
    }
    this.reloadState()
  }
  customSkillsChangeHandle(id: number, value: string) {
    let agent = this.state.agentChanges
    agent.ChangeSkillValue(id, value)
    this.setState({ agentChanges: agent })
  }
  departmentsChangeHandle(id: number, value: boolean) {
    if (value) {
      this.state.agentChanges.AddDepartment(id)
    } else {
      this.state.agentChanges.RemoveDepartment(id)
    }
    this.reloadState()
  }
  userRolesChangeHandle(role: string, value: boolean) {
    if (value) {
      this.state.agentChanges.AddRole(role)
    } else {
      this.state.agentChanges.RemoveRole(role)
    }
    this.reloadState()
  }
  taskChannelsHandleA(id: string, available: boolean) {
    for (let i = 0; i < this.state.taskChannelsChanges.length; i++) {
      if (this.state.taskChannelsChanges[i].Sid === id) {
        let taskChannelsChanges = this.state.taskChannelsChanges
        taskChannelsChanges[i].Available = available
        this.setState({ taskChannelsChanges: taskChannelsChanges })
        return 0;
      }
    }
  }

  taskChannelsHandleC(id: string, capacity: number) {
    let tch = this.state.taskChannelsChanges;
    for (let i = 0; i < tch.length; i++) {
      if (tch[i].Sid === id) {
        tch[i].Capacity = capacity
        this.setState({ taskChannelsChanges: tch })
        return 0;
      }
    }
  }

  handleBool(name: string, checked: boolean) {
    this.state.agentChanges.ChangeBoolean(name, checked)
    this.reloadState()
  }

  reloadState() {
    this.setState(this.state)
  }

  handleMulty(id: number, checked: boolean) {
    if (checked) {
      this.state.agents.Add(id)
    } else {
      this.state.agents.Remove(id)
    }
    this.reloadState()
  }
  multyAplly() {
    this.state.agents.Agents.forEach(id => {
      AgentSkillsActions.updateAgent(id, this.state.agentChanges)
        .then();
    });

  }

  validate() {
    let valid = (this.state.agentChanges.Departments.length > 0) &&
      (this.state.agentChanges.Roles.length > 0) &&
      (this.state.agentChanges.Booking || this.state.agentChanges.Complains || this.state.agentChanges.Support || this.state.agentChanges.Membership)

    let skillsValid = true
    for (let i = 0; i < this.state.agentChanges.Skills.length; i++) {
      if (this.state.agentChanges.Skills[i].Value.length === 0) {
        skillsValid = false
        break;
      }
    }
    let taskChannelsValid = false
    if (this.state.showChannels) {
      for (let i = 0; i < this.state.taskChannelsChanges.length; i++) {
        if (this.state.taskChannelsChanges[i].Available) {
          taskChannelsValid = true
          break;
        }
      }
    } else {
      taskChannelsValid = true
    }
    return valid && skillsValid && taskChannelsValid
  }


  handleClose() {
    this.setState({ alert: false })
  }
  handleCloseError() {
    this.setState({ errorAlert: false })
  }

  handleTeam(teamId: string) {
    let o = this.state.agentChanges

    if (teamId === 'null') {
      o.TeamId = null
    } else {
      o.TeamId = parseInt(teamId)
    }

    this.setState({ agentChanges: o })
  }
  closeDiscardConfirm() {
    this.setState({ discardConfirmShow: false })
  }
  closeDeleteConfirm() {
    this.setState({ deleteConfirmShow: false })
  }
  showDiscardConfirm() {
    this.setState({ discardConfirmShow: true })
  }
  confirmDiscardConfirm() {
    this.loadAgent()
    this.setState({ discardConfirmShow: false })
  }
  handleAccess(isEnabled: number) {
    AgentSkillsActions.accessAgent(this.agentId, (isEnabled === 1))
      .then(agent => {
        if (agent instanceof Agent) {
          this.setState({ agent: agent })
          this.createAgentEdit()
        }
      })
  }
  deleteAgent() {
    AgentSkillsActions.deleteAgent(this.state.agent.Id)
      .then((data) => {
        this.closeDeleteConfirm()
        if (typeof data === "number") {
          this.loadAgent()
        } else {
          let errors = (data.errors === undefined) ? [data.error] : data.errors
          this.setState({ errors: errors, errorAlert: true, saving: false })
        }
      })
  }
  handleChangeKPIs(kpis: AgentCustomKPI[]) {
    this.setState({ customKPIs: kpis })
  }
  render() {
    return (
      <div>
        <Container maxWidth={false}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
              <Grid container spacing={1}><Grid item xs={12} md={2}>
                <div className={`${(this.state.agentChanges.IsNew) ? styles.DisplayNone : ''}`}>
                  <label className={styles.FormLabel}>Access to Twilio Flex</label>
                  <div className={styles.SwitchContainer}>
                    <div className={styles.Switch} >
                      <input type="radio" id="switch_left" name="switch" value="0" checked={!this.state.agentChanges.TwilioAccess} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAccess(parseInt(event.target.value))} />
                      <label htmlFor="switch_left">Disabled</label>
                      <input type="radio" id="switch_right" name="switch" value="1" checked={this.state.agentChanges.TwilioAccess} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAccess(parseInt(event.target.value))} />
                      <label htmlFor="switch_right">Enabled</label>
                    </div>
                  </div>
                </div>
              </Grid>
                <Grid item xs={12} md={6}>
                  <div className={mainStyles.Header}>{(this.state.agentChanges.IsNew) ? 'Add Agent' : 'Edit Agent skills'}</div>
                  <div className={mainStyles.AgentName}> {this.state.agent.FirstName} {this.state.agent.LastName} ({this.state.agent.Nickname})
                  </div>
                </Grid>
                <Grid item xs={12} md={4} className={`${(!this.state.agentChanges.TwilioAccess) ? styles.DisplayNone : ''}`}>
                  <div className={styles.ButtonMargin}>
                    <label className={styles.FormLabel}>Agent Team</label>
                    <select className={`${styles.FormControl}  ${styles.FullWidth}`} value={this.state.agentChanges.TeamId || 'null'} onChange={event => this.handleTeam(event.target.value)}>
                      <option key={-1} value='null' >No Team</option>
                      {this.state.teams.map((row, index) => (
                        <option key={row.Id} value={row.Id} >{row.Name}</option>
                      ))}
                    </select>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} className={`${(!this.state.agentChanges.TwilioAccess) ? styles.DisplayNone : ''}`}>
                  <DepartmentsComponent departments={this.state.departments} agentDepartments={this.state.agentChanges.Departments} handleChange={this.departmentsChangeHandle} />
                  <ActivitiesSkillsComponent agent={this.state.agentChanges} handleChange={this.handleBool.bind(this)} />
                  <AccessTypeComponent userRoles={this.state.agentChanges.Roles} handleChange={this.userRolesChangeHandle.bind(this)} />
                  <AgentKPIComponent agent={this.state.agentChanges} handleChange={this.handleBool.bind(this)} />
                </Grid>
                <Grid item xs={12} md={4} className={`${(!this.state.agentChanges.TwilioAccess) ? styles.DisplayNone : ''} ${styles.FullHeight}`}>
                  <CustomKPIsComponent
                    agentCustomKPIs={this.state.customKPIs}
                    handleChange={(kpis: AgentCustomKPI[]) => this.handleChangeKPIs(kpis)}
                  />
                  <CustomSkillsComponent skills={this.state.customSkills} agentSkills={this.state.agentChanges.Skills} skillsReload={this.skillsReload.bind(this)} handleChange={(id, value) => this.customSkillsChangeHandle(id, value)} handleChangeAvailability={(id, available) => this.customSkillsAvailabilityChangeHandle(id, available)} />
                </Grid>
                <Grid item xs={12} md={4} className={`${(!this.state.agentChanges.TwilioAccess) ? styles.DisplayNone : ''}`}>{this.state.showChannels ? <TaskChannelsComponent taskChannels={this.state.taskChannels} agentTaskChannels={this.state.taskChannelsChanges} handleChangeAvailable={this.taskChannelsHandleA.bind(this)} handleChangeCapacity={this.taskChannelsHandleC.bind(this)} /> : null}</Grid>
              </Grid>
              <Grid container spacing={1} className={`${(!this.state.agentChanges.TwilioAccess) ? styles.DisplayNone : ''}`}>
                <Grid item xs={12} md={4}><div className={styles.ButtonMargin}><GreyInverseBtn className={mainStyles.floatRight} fullWidth={true} variant="contained" color="primary" onClick={() => this.showDiscardConfirm()}>Reset</GreyInverseBtn></div></Grid>
                <Grid item xs={12} md={4}><Button className={`${(this.state.agentChanges.IsNew) ? styles.DisplayNone : ''}`} color="secondary" onClick={() => { this.setState({ deleteConfirmShow: true }) }}><DeleteForever /> Delete Agent</Button></Grid>
                <Grid item xs={12} md={4}><div className={styles.ButtonMargin}><GreenBtn disabled={this.state.saving} className={mainStyles.floatRight} onClick={this.updateAgent.bind(this)} fullWidth={true} variant="contained" color="primary">
                  <CircularProgress className={` ${styles.SaveProgress} ${(this.state.saving) ? '' : styles.DisplayNone}`} />&nbsp;&nbsp;Apply</GreenBtn>
                </div></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <ConfirmComponent show={this.state.discardConfirmShow} title="Discard changes" bodyText="Are you sure you want to discard unsaved changes?" close={() => this.closeDiscardConfirm()} confirm={() => this.confirmDiscardConfirm()}></ConfirmComponent>
        <Snackbar open={this.state.alert} autoHideDuration={5000} onClose={() => this.handleClose()} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Alert onClose={() => this.handleClose()} severity="success" variant="filled">
            Saved!
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.errorAlert} autoHideDuration={5000} onClose={() => this.handleCloseError()} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Alert onClose={() => this.handleCloseError()} severity="error" variant="filled">
            Error<br />
            <div className={styles.AlertError}>{this.state.errors}</div>
          </Alert>
        </Snackbar>
        <Backdrop className={styles.Backdrop} open={this.state.backdrop}>
          <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
          <p className={styles.BackdropProgress}>Loading...</p>
        </Backdrop>
        <ConfirmComponent show={this.state.deleteConfirmShow} title={"Delete Agent " + this.state.agent.FirstName + " " + this.state.agent.LastName} bodyText="Are you sure you want to delete agent?" close={() => this.closeDeleteConfirm()} confirm={() => this.deleteAgent()}></ConfirmComponent>

      </div>
    )
  }
}
