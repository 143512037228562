import { Agent } from "./Agent";
import { AgentCustomSkill } from "./AgentCustomSkill";

export class AgentChanges {
    private is_new: boolean = true    
    public get IsNew() : boolean {
        return this.is_new
    }

    private twilio_access: boolean = true

    public get TwilioAccess(): boolean {
        return this.twilio_access
    }

    public set TwilioAccess(v: boolean) {
        this.twilio_access = v
    }

    private isChanged: boolean = false    

    public get IsChanged(): boolean {
        return this.isChanged
    }

    private departments: number[] = []

    public get Departments(): number[] {
        return this.departments
    }

    public set Departments(v: number[]) {
        this.departments = v;
    }

    public RemoveDepartment(id: number) {
        this.departments.splice(this.departments.indexOf(id), 1)
    }

    public AddDepartment(id: number) {
        this.departments.push(id)
    }

    private roles: string[] = []

    public get Roles(): string[] {
        return this.roles
    }

    public set Roles(v: string[]) {
        this.roles = v;
    }

    public RemoveRole(role: string) {
        this.roles.splice(this.roles.indexOf(role), 1)
    }

    public AddRole(role: string) {
        this.roles.push(role)
    }

    private skills: AgentCustomSkill[] = []   
    
    public get Skills() : AgentCustomSkill[] {
        return this.skills
    }
    public AddSkill(id:number, value:string){
        this.skills.push(new AgentCustomSkill({id:id,value:value}))
    }
    public ChangeSkillValue(id:number,value:string){
        const skillId = this.FindSkill(id)
        if(id>=0){
            this.skills[skillId].Value = value
        }
    }
    public RemoveSkill(id:number){
        const skillId = this.FindSkill(id)
        if(id>=0){
            this.skills.splice(skillId, 1)
        }
    }

    public FindSkill(id:number):number{
        for(let i=0;i<this.skills.length;i++){
            if(this.skills[i].Id === id){
                return i
            }
        }
        return -1
    }

    private booking: boolean = false
    public get Booking(): boolean {
        return this.booking
    }      
    
    private support: boolean = false
    public get Support(): boolean {
        return this.support
    }

    private complains: boolean = false
    public get Complains(): boolean {
        return this.complains
    }

    private membership: boolean = false
    public get Membership(): boolean {
        return this.membership
    }

    private voicemail: boolean = false
    public get Voicemail(): boolean {
        return this.voicemail
    }

    private marketing: boolean = false
    public get Marketing(): boolean {
        return this.marketing
    }

    private dsr: boolean = false
    public get Dsr(): boolean {
        return this.dsr
    }

    private reception: boolean = false
    public get Reception(): boolean {
        return this.reception
    }

    private adl: boolean = false
    public get Adl(): boolean {
        return this.adl
    }
    private best_booker: boolean = false
    public get BestBooker(): boolean {
        return this.best_booker
    }
    private best_sel: boolean = false
    public get BestSel(): boolean {
        return this.best_sel
    }
    private cancelation_ratio: boolean = false
    public get CancelationRatio(): boolean {
        return this.cancelation_ratio
    }

    private team_id:number | null = null    
    public get TeamId() : number | null {
        return this.team_id
    }    
    public set TeamId(v : number | null) {
        if(v === 0){ v = null }
        this.team_id = v;
    }
    

    public ChangeBoolean(key: string, value: boolean) {
        switch (key) {
            case 'booking':
                this.booking = value
                break;
            case 'support':
                this.support = value
                break;
            case 'complains':
                this.complains = value
                break;
            case 'membership':
                this.membership = value
                break;
            case 'voicemail':
                this.voicemail = value
                break;
            case 'marketing':
                this.marketing = value
                break;
            case 'dsr':
                this.dsr = value
                break;
            case 'reception':
                this.reception = value
                break;
            case 'adl':
                this.adl = value
                break;
            case 'best_booker':
                this.best_booker = value
                break;
            case 'best_sel':
                this.best_sel = value
                break;
            case 'cancelation_ratio':
                this.cancelation_ratio = value
                break;
            default:
                break;
        }
    }
    constructor(o: Agent | undefined){
        if(o !== undefined){
            this.is_new = o.IsNew
            this.booking = o.Booking
            this.support = o.Support
            this.complains = o.Complains
            this.membership = o.Membership
            this.voicemail = o.Voicemail
            this.marketing = o.Marketing
            this.dsr = o.Dsr
            this.reception = o.Reception
            this.adl = o.Adl
            this.best_booker = o.BestBooker
            this.best_sel = o.BestSel
            this.cancelation_ratio = o.CancelationRatio
            this.departments = o.Departments
            this.skills = o.Skills
            this.roles = o.Roles
            this.team_id = o.TeamId
            this.twilio_access = o.TwilioAccess
        }
    }

}