import { Expression, ExpressionRule } from "./ExpressionBuilderTypes";

export class WorkersTargetConverter {

    public static ConvertToTwilio(expression: Expression, prefix: string = '') {
        let arr: string[] = []

        

        if (expression.rules.length === 0) {
            return '1==1'
        }

        expression.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                arr.push(this.parseRule(item, prefix))
            }
            if (item.condition !== undefined) {
                let subArr = this.parseExpression(item, prefix)
                if (subArr) {
                    arr.push(subArr)
                }
            }
        });

        return arr.join(' ' + expression.condition + ' ')
    }

    private static parseRule(element: ExpressionRule, prefix: string = '') {
        let value = element.value
        if (Array.isArray(value)) {
                value = '[' + (value as Array<number | string | boolean>).map((elem: (number | string | boolean))=> { return this.getVal(elem)}).join(',')+ ']'           
        } else {
            value = this.getVal(value)
        }
        if (element.field === 'exist_customer') return (value === 'true') ? 'crm_customer.id > 0': 'crm_customer.id <= 0'
        if (element.field === 'is_member') return (value === 'true') ? 'is_member>0': 'is_member <= 0'
        return ((prefix !== '') ? prefix + '.' : '') + element.field + " " + (element.operator === '=' ? '==' : element.operator) + " " + value
    }

    private static getVal(elem: (number | string | boolean)):string{
        if(typeof elem === 'number'){
            return elem.toString()
        }
        if(typeof elem === 'boolean'){
            return (elem) ? 'true' : 'false'
        }
            return '\''+elem+'\''
    }
    private static parseExpression(element: Expression, prefix: string = '') {
        let arr: string[] = []

        if (element.rules.length === 0) {
            return false
        }

        element.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                arr.push(this.parseRule(item, prefix))
            }
            if (item.condition !== undefined) {
                let subArr = this.parseExpression(item, prefix)
                if (subArr) {
                    arr.push(subArr)
                }
            }
        });
        return '(' + arr.join(' ' + element.condition + ' ') + ')'
    }

    private static parseSortExpression(element: Expression, prefix: string = '') {
        let arr: string[] = []

        if (element.rules.length === 0) {
            return ''
        }

        element.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                arr.push(this.parseSortRule(item, prefix))
            }
            if (item.condition !== undefined) {
                let subArr = this.parseSortExpression(item, prefix)
                if (subArr) {
                    arr.push(subArr)
                }
            }
        });
        return '(' + arr.join(' ' + element.condition + ' ') + ')'
    }

    private static parseSortRule(element: ExpressionRule, prefix: string = '') {
        return ((prefix !== '') ? prefix + '.' : '') + element.field + " " + element.value
    }

    public static ConvertSortingToTwilio(expression: Expression, prefix: string = '') {
        let arr: string[] = []

        if (expression.rules.length === 0) {
            return ''
        }

        expression.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                arr.push(this.parseSortRule(item, prefix))
            }
            if (item.condition !== undefined) {
                let subArr = this.parseSortExpression(item, prefix)
                if (subArr) {
                    arr.push(subArr)
                }
            }
        });

        return arr.join(' ' + expression.condition + ' ')
    }

}