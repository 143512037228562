import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import React from 'react';
import styles from './styles.module.css';

export interface IProps {
  show: boolean,
  title: string,
  bodyText: string,
  close: () => void,
  confirm: () => void,
}

export default class ConfirmComponent extends React.Component<IProps> {

  render() {
    return (
      <div>
        <Dialog open={this.props.show} onClose={this.props.close}>
          <DialogTitle className={styles.TitleText}>
          <span>{this.props.title}</span>          
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span className={styles.BodyText}>{this.props.bodyText}</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className={styles.CloseBtn} onClick={this.props.close}>No</button>
              
            <button className={styles.ConfirmBtn} onClick={this.props.confirm}>Yes</button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
