export class AgentsMultyAplly{
    private agents:number[] = []
    
    public get Agents() : number[] {
        return this.agents
    }
    public Remove(agentId: number) {
        this.agents.splice(this.agents.indexOf(agentId), 1)
    }

    public Add(agentId: number) {
        this.agents.push(agentId)
    }
}