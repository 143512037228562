import React from 'react';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';
import { AgentChanges } from '../../models/AgentSkills/AgentChanges';

export interface IProps {
  agent: AgentChanges,
  handleChange: (name: string, checked: boolean) => void
}

export default class ActivitiesSkillsComponent extends React.Component<IProps> {
  private componentBody = React.createRef<HTMLDivElement>()

  _activeSkills = [
    { id: 'booking', name: 'Booking' },
    { id: 'complains', name: 'Complains' },
    { id: 'support', name: 'Support' },
    { id: 'membership', name: 'Membership' },
    { id: 'voicemail', name: 'Voicemail' },
    { id: 'marketing', name: 'Marketing' },
    { id: 'dsr', name: 'dsr' },
    { id: 'reception', name: 'reception' },
  ];

  getAgentProp(name: string): boolean {
    let val: boolean

    switch (name) {
      case 'booking':
        val = this.props.agent.Booking
        break
      case 'complains':
        val = this.props.agent.Complains
        break
      case 'support':
        val = this.props.agent.Support
        break
      case 'membership':
        val = this.props.agent.Membership
        break 
      case 'voicemail':
        val = this.props.agent.Voicemail
        break
      case 'marketing':
        val = this.props.agent.Marketing
        break
      case 'dsr':
        val = this.props.agent.Dsr
        break
      case 'reception':
        val = this.props.agent.Reception
        break
      default:
        val = false;
        break

    }
    return val
  }

  valid() {
    return this.props.agent.Booking || this.props.agent.Complains || this.props.agent.Support || this.props.agent.Membership || this.props.agent.Voicemail || this.props.agent.Marketing || this.props.agent.Dsr  || this.props.agent.Reception
  }

  activitiesCount(){
    let counter = 0
    this._activeSkills.forEach(skill=>{
      if(this.getAgentProp(skill.id)){ 
        counter++
      }
    })
    return counter
  }
  render() {
    let error = <div></div>
    if (!this.valid()) { error = <div className={styles.Error}>Select one or more items</div> }
    return (
      <div>
        <div className={mainStyles.ComponentHeader}><span >Activities</span><span className={mainStyles.HeadRightCounter}> {this.activitiesCount()}</span><span className={(this.componentBody && this.componentBody.current && this.componentBody.current.clientWidth>330)?mainStyles.FloatRight:''}>&nbsp;Operation Skills</span></div>
        <div ref={this.componentBody} className={`${mainStyles.ComponentBody} ${(!this.valid()) ? styles.ComponentInvalid : ''} `} >
          {error}
          {this._activeSkills.map((skill, index) => {
            return (
              <label className={`${styles.SkillsCheckbox} ${styles.Underlined}`} key={skill.id} >
                <input type="checkbox" value={skill.id} checked={this.getAgentProp(skill.id)} onChange={(event: React.ChangeEvent<HTMLInputElement>)=>this.props.handleChange(event.target.value, event.target.checked)} />
                <span className={`${(this.getAgentProp(skill.id) ? styles.CheckLabelChecked : styles.CheckLabel)}`}>{skill.name}</span>
              </label>
            )
          })}
        </div>
      </div>
    )
  }
}

