import { Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress, Button } from '@material-ui/core';
import React from 'react';
import styles from './styles.module.css';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import { LocationBrandSettings } from '../../models/LocationBrands/LocationBrandsSettings';
import { LocationBrandsActions } from '../../actions/LocationBrandsActions';
import { GreenBtn } from '../Styled/StyledButtons';
import UploadFiles from './UploadAudioComponent';
import { DeleteForever } from '@material-ui/icons';

export interface IProps {
}
interface IState {
    brands: LocationBrandSettings[]
    settingId: number
    backdrop: boolean
    ivrTypes: any[]
    saving: boolean
}
export default class LocationBrandsComponent extends React.Component<IProps, IState> {

    state: IState = {
        brands: [],
        settingId: -1,
        backdrop: false,
        ivrTypes: [],
        saving: false
    }

    componentDidMount() {
        document.title = 'Location Brands Settings'
        this.setState({ backdrop: true })
        this.loadIvrTypes()
        this.loadLocationBrands()
    }

    loadLocationBrands() {
        LocationBrandsActions.get().then((resp) => {
            this.setState({ brands: resp, backdrop: false })
        })
    }
    loadIvrTypes() {
        LocationBrandsActions.getIvrTypes().then((resp) => {
            this.setState({ ivrTypes: resp })
        })
    }
    handleChangeIvrType(brandId: number, typeId: number) {
        const brands = this.state.brands
        const index = brands.findIndex(item => { return item.id === brandId })
        brands[index].ivr_type_id = typeId
        this.setState({ brands: brands })
    }

    handleChangeUrl(brandId: number, url: string | undefined) {
        const brands = this.state.brands
        const index = brands.findIndex(item => { return item.id === brandId })
        brands[index].audio_url = url
        this.setState({ brands: brands })
    }

    save() {
        this.setState({ saving: true })
        LocationBrandsActions.update(this.state.brands).then(resp => { this.setState({ brands: resp, saving: false }) })
    }
    render() {
        return (
            <div>
                <Container maxWidth="lg">
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className={mainStyles.Header}>Locations Brands</div>
                                    <div className={mainStyles.AgentName}> &nbsp;</div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="right">
                                                <GreenBtn onClick={() => this.save()} disabled={this.state.saving} fullWidth={true}>
                                                    <CircularProgress className={` ${styles.SaveProgress} ${(this.state.saving) ? '' : styles.DisplayNone}`} />&nbsp;&nbsp;Save
                                                </GreenBtn>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={mainStyles.FlexColScroll}>
                                        {this.state.brands.map((brand, index) => (
                                            <TableRow key={brand.id}>
                                                <TableCell align="left">{brand.name}</TableCell>
                                                <TableCell>
                                                    <select className={`${styles.FormControl}  ${styles.FullWidth}`} value={brand.ivr_type_id} onChange={event => this.handleChangeIvrType(brand.id, parseInt(event.target.value))}>
                                                        {this.state.ivrTypes.map((ivrType, index) => (
                                                            <option key={ivrType.id} value={ivrType.id} >{ivrType.name}</option>
                                                        ))}
                                                    </select>
                                                </TableCell>
                                                <TableCell>
                                                    {brand.audio_url ?
                                                        <div>
                                                            <a href={brand.audio_url} target='_blank'>{brand.audio_url}</a>
                                                            <Button color="secondary" onClick={() => { this.handleChangeUrl(brand.id, undefined)}}><DeleteForever /></Button>
                                                        </div>
                                                        :                                                        
                                                        <UploadFiles onUpload={(url => this.handleChangeUrl(brand.id, url))} />
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>
                <Backdrop className={styles.Backdrop} open={this.state.backdrop}>
                    <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
                    <p className={styles.BackdropProgress}>Loading...</p>
                </Backdrop>
            </div>
        )
    }
}