export interface IAgentCustomSkill{
    id: number
    value: string
}

export class AgentCustomSkill{
    private id: number = 0
    
    public get Id() : number {
        return this.id
    }   
    
    public set Id(v : number) {
        this.id = v;
    }

    private value: string = ''
    
    public get Value() : string {
        return this.value
    }    
    
    public set Value(v : string) {
        this.value = v;
    }
    
    constructor(o:IAgentCustomSkill|undefined){
        if(o !== undefined){
            this.id = o.id
            this.value = o.value
        }
    }
}