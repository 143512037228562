import React from 'react';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';
import { Container, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { Add, DeleteForever } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import { BlueInverseBtn } from '../Styled/StyledButtons';
import { Queue } from '../../models/Queue/Queue';
import { QueuesManagementActions } from '../../actions/QueuesManagementActions';
import { WorkspacesActions } from '../../actions/WokspacesActions';
import { Workspace } from '../../models/Workspaces/Workspace';
import { Activity } from '../../models/Workspaces/Activity';
import { Alert } from '@material-ui/lab';

interface IState {
  queueEdit: Queue,
  editShow: boolean,
  workspaces: Workspace[],
  queues: Queue[],
  currentWorkspace: string,
  activities: Activity[],
  backdrop: boolean,
  alert: boolean,
  errorAlert: boolean,
  errors: string[]
}

export default class QueuesManagmentComponent extends React.Component<any, IState> {

  state: IState = {
    queueEdit: new Queue(undefined),
    editShow: false,
    currentWorkspace: '',
    queues: [],
    workspaces: [],
    activities: [],
    backdrop: true,    
    alert: false,
    errorAlert: false,
    errors: [],
  }

  componentDidMount() {
    document.title = 'Queues'
    this.loadWorkspaces()
  }

  newQueue() {
    this.setState({ queueEdit: new Queue(undefined), editShow: true })
  }

  refreshState() {
    this.setState(this.state)
  }
  loadWorkspaces() {
    WorkspacesActions.getWorkspaces()
      .then(workspaces => {
        if (workspaces.length > 0) {
          this.setState({ workspaces: workspaces, currentWorkspace: workspaces[0].Sid })
          this.loadQueues()
        }
      })
  }
  loadQueues() {
    QueuesManagementActions.getQueues(this.state.currentWorkspace)
      .then(queues => { this.setState({ queues: queues, backdrop: false }) })
  }


  deleteQueue(sid: string) {
    QueuesManagementActions.deleteQueue(this.state.currentWorkspace, sid)
      .then((data) => {
        if (typeof data === 'boolean') {
          this.loadQueues()
        } else {
          
          let errors = (data.errors === undefined) ? [data.error] : data.errors
          this.setState({ errorAlert: true, backdrop: false, errors: errors })
         
        }

      })
  }

  handleClose() {
    this.setState({ alert: false })
  }
  handleCloseError() {
    this.setState({ errorAlert: false })
  }
  render() {
    return (
      <div>
        <Container maxWidth="lg">
          <Grid container spacing={1}>
            <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={mainStyles.Header}> &nbsp;</div>
                  <div className={mainStyles.AgentName}>Queues Management</div>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}><BlueInverseBtn variant="contained" fullWidth={true} href={'/queue-create'}><Add /> Add queue</BlueInverseBtn></Grid>

              </Grid>
            </Grid>
            <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Queue Name</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={mainStyles.FlexColScroll}>
                    {this.state.queues.map((row, index) => (
                      <TableRow key={row.Sid}>
                        <TableCell align="left">{row.Name}</TableCell>
                        <TableCell align="right">
                          <Button color="primary" href={'/queue/sid/' + row.Sid} ><CreateIcon /></Button>
                          <Button color="secondary" onClick={() => { this.deleteQueue(row.Sid) }}><DeleteForever /></Button>
                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
        <Snackbar open={this.state.alert} autoHideDuration={5000} onClose={() => this.handleClose()} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Alert onClose={() => this.handleClose()} severity="success" variant="filled">
            Saved!</Alert>
        </Snackbar>
        <Snackbar open={this.state.errorAlert} autoHideDuration={5000} onClose={() => this.handleCloseError()} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Alert onClose={() => this.handleCloseError()} severity="error" variant="filled">
            Error<br />
            <div className={styles.AlertError}>{this.state.errors}</div>
          </Alert>
        </Snackbar>
        <Backdrop className={styles.Backdrop} open={this.state.backdrop}>
          <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
          <p className={styles.BackdropProgress}>Loading...</p>
        </Backdrop>
      </div>
    )
  }
}


