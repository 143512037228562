import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const GreyBtn = withStyles({
  root: {
    backgroundColor: "#666666",
    borderRadius: "8px",
    border: "solid 1px #666666",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "0.4px",
    textAlign: "center",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#fff",
      color: "#666666",
    }
  },
})(Button);
   
export const GreyBlueBtn = withStyles({
  root: {
    backgroundColor: "#eef1f5",
    borderRadius: "8px",
    border: "solid 1px #3f73b0",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "0.4px",
    textAlign: "center",
    color: "#3f73b0",
    '&:hover': {
      backgroundColor: "#3f73b0",
      color: "#eef1f5",
    }
  },
})(Button);

export const GreyInverseBtn = withStyles({
  root: {
    
    borderRadius: "8px",
    border: "solid 1px #666666",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "0.4px",
    textAlign: "center",    
    backgroundColor: "#fff",
    color: "#666666",
    '&:hover': {
      backgroundColor: "#666666",
      color: "#fff",
    }
  },
})(Button);

export const BlueBtn = withStyles({
  root: {
    backgroundColor: "#3f73b0",
    borderRadius: "8px",
    border: "solid 1px #3f73b0",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "0.4px",
    textAlign: "center",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#fff",
      color: "#3f73b0",
    }
  },
})(Button);
   
export const BlueInverseBtn = withStyles({
  root: {    
    borderRadius: "8px",
    border: "solid 1px #3f73b0",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "0.4px",
    textAlign: "center",    
    backgroundColor: "#fff",
    color: "#3f73b0",
    '&:hover': {
      backgroundColor: "#3f73b0",
      color: "#fff",
    }
  },
})(Button);

export const GreenBtn = withStyles({
  root: {    
    borderRadius: "8px",
    border: "solid 1px #378d41",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "0.4px",
    textAlign: "center",    
    backgroundColor: "#378d41",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#fff",
      color: "#378d41",
    },
    '&:disabled': {
      backgroundColor: "#BFDBC2",
      color: "#378d41",
    }
  },
})(Button);

export const GreenInverseBtn = withStyles({
  root: {    
    borderRadius: "8px",
    border: "solid 1px #378d41",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "0.4px",
    textAlign: "center",   
    backgroundColor: "#fff",
    color: "#378d41",
    '&:hover': {
      backgroundColor: "#378d41",
      color: "#fff",
    } 
    
  },
})(Button);

export const RedBtn = withStyles({
  root: {    
    borderRadius: "8px",
    border: "solid 1px #b00000",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "0.4px",
    textAlign: "center",    
    backgroundColor: "#fff",
    color: "#b00000",
    '&:hover': {
      backgroundColor: "#b00000",
      color: "#fff",
    }
  },
})(Button);

export const RedInverseBtn = withStyles({
  root: {    
    borderRadius: "8px",
    border: "solid 1px #b00000",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "0.4px",
    textAlign: "center",    
    backgroundColor: "#fff",
    color: "#b00000",
    '&:hover': {
      backgroundColor: "#b00000",
      color: "#fff",
    }
  },
})(Button);