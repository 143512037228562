import React from "react"
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IHistory } from ".";

const History = ({ history = [] }) => {
    return (
        <>
            <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Blocked at</TableCell>
                                <TableCell align="right">Blocked by</TableCell>
                                <TableCell align="right">Interval</TableCell>
                                <TableCell align="right">Unblocked by</TableCell>
                                <TableCell align="left">Unblocked at</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={mainStyles.FlexColScroll}>
                            {history.map((row: IHistory, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left">{row.created_at}</TableCell>
                                    <TableCell align="right">{row.created_by}</TableCell>
                                    <TableCell align="right">{row.interval}</TableCell>
                                    <TableCell align="right">{row.stopped_by}</TableCell>
                                    <TableCell align="left">{row.stopped_at}</TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export default History