import { Grid } from '@material-ui/core';
import React from 'react';
import styles from './styles.module.css';
import { WorkspacesActions } from '../../actions/WokspacesActions';
import { GUID } from '../../models/Guid';
import { Trigger } from '../../models/Workflows/Trigger';
import { DepartmentsActions } from '../../actions/DepartmentsActions';
import { Department } from '../../models/AgentSkills/Department';
import { TaskChannel } from '../../models/Workspaces/TaskChannel';

export interface IProps {
    currentWorkspace: string
    triggers: Trigger[]
    changeHandle: (triggers: Trigger[]) => void
}

interface IState {
    errors: string[],
    triggers: Trigger[]
    departments: Department[],
    taskChannels: TaskChannel[],
    selectedActivity: string
    selectedTaskChannel: string
    selectedDepartments: string[]
    selectedPaid: string[]
}

export default class WorkflowTriggersComponent extends React.Component<IProps, IState> {

    state: IState = {
        errors: [],
        triggers: [],
        departments: [],
        taskChannels: [],
        selectedActivity: '',
        selectedTaskChannel: '',
        selectedDepartments: [],
        selectedPaid: []
    }
    selectedActivity: string = ''
    selectedTaskChannel: string = ''
    selectedDepartments: string[] = []
    selectedPaid: string[] = []
    activities = [
        { id: 'booking', name: 'Booking' },
        { id: 'complains', name: 'Complains' },
        { id: 'support', name: 'Support' },
        { id: 'membership', name: 'Membership' },
        { id: 'voicemail', name: 'Voicemail' },
        { id: 'marketing', name: 'Marketing' },
        { id: 'dsr', name: 'Dsr' },
        { id: 'direct', name: 'Direct' }
    ];
    componentDidMount() {
        this.loadDepartments()
    }
    componentDidUpdate(prevProps: IProps) {
        if (prevProps.currentWorkspace !== this.props.currentWorkspace && this.props.currentWorkspace.length > 0) {
            this.loadTaskChannels()
        }
        if (prevProps.triggers !== this.props.triggers) {
            this.getTriggersValues(this.props.triggers)
        }
    }

    loadTaskChannels() {
        WorkspacesActions.getTaskChannels(this.props.currentWorkspace)
            .then(taskChannels => {
                this.setState({ taskChannels: taskChannels })
            })
    }
    loadDepartments() {
        DepartmentsActions.getDepartments()
            .then(departments => {
                this.setState({ departments: departments })
            })
    }

    handleActivitiesChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ selectedActivity: event.target.value })
        this.selectedActivity = event.target.value
        this.changeTriggers()
    }

    handleTaskChannelsChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({ selectedTaskChannel: event.target.value })
        this.selectedTaskChannel = event.target.value
        this.changeTriggers()
    }

    handleDepartmentsChange(event: React.ChangeEvent<{ value: unknown }>) {
        const value: string[] = [];
        const { options } = event.target as HTMLSelectElement;
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ selectedDepartments: value })
        this.selectedDepartments = value
        this.changeTriggers()
    }
    handlePaidChange(event: React.ChangeEvent<{ value: unknown }>) {
        const value: string[] = [];
        const { options } = event.target as HTMLSelectElement;
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ selectedPaid: value })
        this.selectedPaid = value
        this.changeTriggers()
    }
    changeTriggers() {
        let triggers: Trigger[] = []
        this.selectedDepartments.forEach((department_id) => {
            this.selectedPaid.forEach((is_paid) => {
                let paid:boolean = (is_paid==='1')
                triggers.push(new Trigger({ department_id: department_id, activity: this.selectedActivity, channel: this.selectedTaskChannel, is_paid: paid }))
            })
        })
        if (triggers.length > 0) {
            this.props.changeHandle(triggers)
        }
    }

    getTriggersValues(triggers: Trigger[]) {
        if (triggers.length > 0) {
            let departments: string[] = []
            let selectedPaid: string[] = []
            triggers.forEach((trigger) => {
                if (departments.indexOf(trigger.Department) === -1) {
                    departments.push(trigger.Department)
                }
                let paid = (trigger.IsPaid) ? '1': '0'
                if (selectedPaid.indexOf(paid) === -1) {
                    selectedPaid.push(paid)
                }
            })
            this.setState({
                selectedDepartments: departments,
                selectedPaid:selectedPaid,
                selectedActivity: triggers[0].Activity,
                selectedTaskChannel: triggers[0].TaskChannel
            })
            this.selectedDepartments = departments
            this.selectedPaid = selectedPaid
            this.selectedActivity = triggers[0].Activity
            this.selectedTaskChannel = triggers[0].TaskChannel
        }
    }

    render() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12}><div className={styles.SubHead}><span >Trigger this workflow when task matches with:</span></div></Grid>
                    <Grid item xs={12} className={(this.state.errors.length === 0) ? styles.DisplayNone : ''}>
                        <ul>
                            {this.state.errors.map((error: string) => (
                                <li className={styles.ExpressionError} key={GUID.New()}>{error}</li>
                            ))}
                        </ul>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <label className={styles.StepFormLabel}>Activity</label>
                        <select className={`${(this.state.selectedActivity.length === 0) ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} value={this.state.selectedActivity} onChange={(event) => this.handleActivitiesChange(event)} >
                            <option value="" disabled >(select Activity)</option>
                            {this.activities.map((activity) => (
                                <option key={activity.id} value={activity.id}>
                                    {activity.name}
                                </option>
                            ))}
                        </select>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <label className={styles.StepFormLabel}>Task Channel</label>
                        <select className={`${(this.state.selectedTaskChannel.length === 0) ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} value={this.state.selectedTaskChannel} onChange={(event) => this.handleTaskChannelsChange(event)} >
                            <option value="" disabled>(select Task Channel)</option>
                            {this.state.taskChannels.map((taskChannel) => (
                                <option key={taskChannel.Sid} value={taskChannel.Sid}>
                                    {taskChannel.Name}
                                </option>
                            ))}
                        </select>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <label className={styles.StepFormLabel}>Categories</label>
                        <select multiple className={`${(this.state.selectedDepartments.length === 0) ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} value={this.state.selectedDepartments} onChange={(event) => this.handleDepartmentsChange(event)} >
                            {this.state.departments.map((category) => (
                                <option key={category.Id} value={category.Id}>
                                    {category.Name}
                                </option>
                            ))}
                        </select>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <label className={styles.StepFormLabel}>Paid Calls</label>
                        <select multiple className={`${(this.state.selectedPaid.length === 0) ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} value={this.state.selectedPaid} onChange={(event) => this.handlePaidChange(event)} >

                            <option key={0} value={'0'}>
                                For NOT Paid Calls
                            </option>
                            <option key={1} value={'1'}>
                                For Paid Calls
                            </option>
                        </select>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
