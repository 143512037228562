export class Pages{
    private perPage:number;
    private currentPage:number;
    private lastPage:number;

    get PerPage():number{
        return this.perPage
    }
    get CurrentPage():number{
        return this.currentPage
    }
    get LastPage():number{
        return this.lastPage
    }
    constructor(perPage:number, currentPage: number, lastPage:number){
        this.perPage = perPage
        this.currentPage = currentPage
        this.lastPage = lastPage
    }
}