import { AgentCustomSkill, IAgentCustomSkill } from "./AgentCustomSkill";

export interface IAgent{
    id: number
    is_new: boolean
    first_name: string
    last_name: string
    nickname: string
    roles: string[]
    adl: boolean
    best_booker: boolean
    best_sel: boolean
    cancelation_ratio: boolean
    booking: boolean
    support: boolean
    complains: boolean
    membership: boolean
    voicemail: boolean
    marketing: boolean
    dsr: boolean
    reception: boolean
    departments: number[]
    skills: IAgentCustomSkill[]
    team_id: number | null
    twilio_access: boolean
}

export class Agent{
    private id: number = 0
    public get Id() : number {
        return this.id
    }    

    private is_new: boolean = true    
    public get IsNew() : boolean {
        return this.is_new
    }
    
    private firstName: string = '';   
    public get FirstName() : string {
        return this.firstName
    }
    
    private lastName: string = ''
    public get LastName() : string {
        return this.lastName
    }

    private nickname: string = '';
    public get Nickname() : string {
        return this.nickname
    }
    
    private adl: boolean = false;    
    public get Adl() : boolean {
        return this.adl
    }

    private bestBooker: boolean = false;    
    public get BestBooker() : boolean {
        return this.bestBooker
    }

    private bestSel: boolean = false;    
    public get BestSel() : boolean {
        return this.bestSel
    }
    
    private cancelationRatio: boolean = false;    
    public get CancelationRatio() : boolean {
        return this.cancelationRatio
    }

    private booking: boolean = false;    
    public get Booking() : boolean {
        return this.booking
    }

    private support: boolean = false;    
    public get Support() : boolean {
        return this.support
    }

    private complains: boolean = false    
    public get Complains() : boolean {
        return this.complains
    }

    private membership: boolean = false;    
    public get Membership() : boolean {
        return this.membership
    }

    private voicemail: boolean = false;    
    public get Voicemail() : boolean {
        return this.voicemail
    }

    private marketing: boolean = false;    
    public get Marketing() : boolean {
        return this.marketing
    }

    private dsr: boolean = false;    
    public get Dsr() : boolean {
        return this.dsr
    }

    private reception: boolean = false
    public get Reception(): boolean {
        return this.reception
    }

    private roles: string[] = []    
    public get Roles() : string[] {
        return this.roles
    }    
    public set Roles(v : string[]) {
        this.roles = v;
    }    

    private departments: number[] = []   
    public get Departments() : number[] {
        return this.departments
    }
    public set Departments(v: number[]){
        this.departments = v;
    }

    private skills: AgentCustomSkill[] = []   
    
    public get Skills() : AgentCustomSkill[] {
        return this.skills
    }
    public AddSkill(id:number, value:string){
        this.skills.push(new AgentCustomSkill({id:id,value:value}))
    }
    public ChangeSkillValue(id:number,value:string){
        const skillId = this.findSkill(id)
        if(id>=0){
            this.skills[skillId].Value = value
        }
    }
    private findSkill(id:number):number{
        for(let i=0;i<this.skills.length;i++){
            if(this.skills[i].Id === id){
                return i
            }
        }
        return -1
    }
    
    private teamId: number | null = null    
    public get TeamId() : number | null {
        return this.teamId
    }    
    public set TeamId(v : number| null) {
        this.teamId = v;
    }  

    private twilio_access: boolean = true
    public get TwilioAccess(): boolean {
        return this.twilio_access
    }
    public set TwilioAccess(v: boolean) {
        this.twilio_access = v
    }

    constructor(o: IAgent | undefined){
        if(o !== undefined){
        this.id = o.id
        this.is_new = o.is_new
        this.firstName = o.first_name
        this.lastName = o.last_name
        this.nickname = o.nickname
        this.adl = o.adl
        this.bestBooker = o.best_booker
        this.bestSel = o.best_sel
        this.cancelationRatio = o.cancelation_ratio
        this.booking = o.booking
        this.support = o.support
        this.complains = o.complains
        this.membership = o.membership
        this.voicemail = o.voicemail
        this.marketing = o.marketing
        this.dsr = o.dsr
        this.reception = o.reception
        this.roles = o.roles
        this.departments = o.departments
        o.skills.forEach(skill => {
            this.skills.push(new AgentCustomSkill(skill))    
        })
        this.teamId = o.team_id
        this.twilio_access = o.twilio_access
    }
    }
}
