import { colors } from "@material-ui/core"
import React from "react"

interface IProps {
    days: number | null
    hours: number | null
    minutes: number | null
    seconds: number | null
}

var styles = {
    redText: {
      color: "red"
    },
  }

const Counter = ({ days, hours, minutes, seconds }: IProps) => {
    const hasDays = () => {
        return days && days > 0
    }
    const hasHours = () => {
        return hours && hours > 0
    }
    const hasMinutes = () => {
        return minutes && minutes > 0
    }
    const printTime = (value:number|null)=>{
        if(value === null){
            return "00"
        }
        return ("0"+value.toString()).slice(-2)
    }

    return (
        <>
        <span style={styles.redText}>
            {hasDays() ? days + " days " : ""}{hasHours() ? printTime(hours) + ':' : (hasDays() ? '00:' : '')}{hasMinutes() ? printTime(minutes) + ':' : (hasDays() || hasHours() ? '00:' : '')}{printTime(seconds)}
            </span>
        </>
    )
}
export default Counter
