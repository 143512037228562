export interface ITrigger{
    department_id: string,
    activity: string,
    channel: string,
    is_paid: boolean
}

export class Trigger{
    department_id: string = ''
    activity: string = ''
    channel: string = ''
    is_paid: boolean = false

    constructor(o:ITrigger | undefined){
        if(o !== undefined){
            this.department_id = o.department_id
            this.activity = o.activity
            this.channel = o.channel
            this.is_paid = o.is_paid
        }
    }
    
    public get Department() : string {
        return this.department_id
    }
    
    public get Activity() : string {
        return this.activity
    }
    
    public get TaskChannel() : string {
        return this.channel
    }

    public get IsPaid() : boolean {
        return this.is_paid
    }
        
}