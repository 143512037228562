import React from 'react';
import { Button } from '@material-ui/core'
import { Add, DeleteForever } from '@material-ui/icons'
import { CustomSkill } from '../../models/AgentSkills/CustomSkill';
import styles from './styles.module.css'
import { AgentSkillsActions } from '../../actions/AgentSkillsActions';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import { GreyBlueBtn } from '../Styled/StyledButtons';
import AddCustomSkillsComponent from './AddCustomSkillsComponent';
import { AgentCustomSkill } from '../../models/AgentSkills/AgentCustomSkill';
import DeleteSkillApiError from '../../models/ApiErrors/DeleteSkillApiError';
import CustomSkillDeleteComponent from './CustomSkillDeleteComponent';

interface IState {
  skillsOpen: boolean,
  newSkills: CustomSkill[],
  skillDeleteShow: boolean,

  skillDeleteError: DeleteSkillApiError,
  skillForDelete: CustomSkill
}

export interface IProps {
  skills: CustomSkill[],
  agentSkills: AgentCustomSkill[],
  skillsReload: () => void
  handleChange: (skillId: number, value: string) => void
  handleChangeAvailability: (skillId: number, avaliable: boolean) => void
}

export default class CustomSkillsComponent extends React.Component<IProps, IState> {

  state: IState = {
    skillsOpen: false,
    newSkills: [],

    skillDeleteShow: false,
    skillDeleteError: new DeleteSkillApiError(),
    skillForDelete: new CustomSkill()
  }

  newSkillsOpen: boolean = false

  modalSkillsOpen() {
    this.setState({ skillsOpen: true })
  }

  toggleChildMenu() {
    this.setState({ skillsOpen: !this.state.skillsOpen })
  }

  deleteSkill(skill: CustomSkill) {
    AgentSkillsActions.deleteSkill(skill.Id)
      .then((data) => {
        if (data instanceof CustomSkill) {
          this.props.skillsReload()
        } else {
          this.setState({ skillDeleteShow: true, skillDeleteError: data, skillForDelete: skill })
        }
      })

  }
  closeDeleteSkillError() {
    this.setState({ skillDeleteShow: false })
  }
  handleSkillsAdd() {
    this.props.skillsReload()
    this.toggleChildMenu()
  }
  public FindSkill(id: number): number {
    for (let i = 0; i < this.props.agentSkills.length; i++) {
      if (this.props.agentSkills[i].Id === id) {
        return i
      }
    }
    return -1
  }
  isAllChecked() {
    let isAll = true
    this.props.skills.forEach((skill) => {
      if (this.FindSkill(skill.Id) < 0) {
        isAll = false
      }
    })
    return isAll;
  }
  handleCheckedAll() {
    let checked = true
    if (this.isAllChecked()) {
      checked = false
    }
    this.props.skills.forEach(skill => {
      this.props.handleChangeAvailability(skill.Id, checked)
    });
  }
  handleValue(id: number, value: string) {
    const re = /[0-9a-zA-Z_]$|^$/g;
    if (re.test(value)) {
      this.props.handleChange(id, value)
    }
  }
  render() {
    return (<div>
      <div className={mainStyles.ComponentHeader}><span>custom operational skills</span></div>
      <div className={mainStyles.ComponentBody}>
        <table className={styles.Table}>
          <thead>
            <tr>
              <th className={styles.BlackUnderlined}>
                <label className={styles.SkillsCheckbox}>
                  <input type="checkbox" value="all" checked={(this.isAllChecked())} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCheckedAll()} />
                  <span className={`${styles.CheckLabelChecked}`}>NAME</span>
                </label>
              </th>
              <th className={`${styles.ValueCol} ${styles.BlackUnderlined}`}><label className={styles.CheckLabelChecked}>VALUE</label></th>
              <th className={`${styles.DeleteCol} ${styles.BlackUnderlined}`}><label className={styles.CheckLabelChecked}>DELETE</label></th>
            </tr>
          </thead>
          <tbody>
            {this.props.skills.map((row: CustomSkill, index) => (
              <tr key={index} className={`${((this.FindSkill(row.Id) >= 0) ? styles.CheckLabelChecked : styles.CheckLabel)}`}>
                <td className={styles.Underlined}>
                  <label className={styles.SkillsCheckbox} key={row.Id} >
                    <input type="checkbox" value={row.Id} checked={(this.FindSkill(row.Id) >= 0)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleChangeAvailability(parseInt(event.target.value), event.target.checked)} />
                    <span>{row.Key}</span>
                  </label>
                </td>
                               
                <td className={`${styles.ValueCol} ${styles.Underlined}`}>
                  {(row.TypeId === 1)?
                    <input placeholder="value" className={`${((this.FindSkill(row.Id) >= 0) && (this.props.agentSkills[this.FindSkill(row.Id)].Value.length === 0)) ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} value={(this.FindSkill(row.Id) < 0) ? '' : this.props.agentSkills[this.FindSkill(row.Id)].Value} disabled={this.FindSkill(row.Id) < 0} onChange={event => this.handleValue(row.Id, event.target.value)} />
                    :
                    ''
                  }
                  {(row.TypeId === 2)?
                    <input placeholder="value" type="number" className={`${((this.FindSkill(row.Id) >= 0) && (this.props.agentSkills[this.FindSkill(row.Id)].Value.length === 0)) ? styles.FormInvalid : ""} ${styles.FormControl} ${styles.FullWidth}`} value={(this.FindSkill(row.Id) < 0) ? '' : this.props.agentSkills[this.FindSkill(row.Id)].Value} disabled={this.FindSkill(row.Id) < 0} onChange={event => this.handleValue(row.Id, event.target.value)} />
                    :
                    ''
                  }
                </td>
                <td className={`${styles.DeleteCol} ${styles.Underlined}`} ><Button color="secondary" onClick={() => { this.deleteSkill(row) }}><DeleteForever /></Button></td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      <div>
        <div className={styles.ButtonMargin}><GreyBlueBtn color="primary" fullWidth={true} onClick={() => this.modalSkillsOpen()}><Add /> add skills</GreyBlueBtn></div>
        <AddCustomSkillsComponent open={this.state.skillsOpen} addNewSkills={() => this.handleSkillsAdd()} toggle={() => this.toggleChildMenu()}></AddCustomSkillsComponent>
        <CustomSkillDeleteComponent open={this.state.skillDeleteShow} error={this.state.skillDeleteError} toggle={() => this.closeDeleteSkillError()} customSkill={this.state.skillForDelete} />
      </div>
    </div>)
  }
}

