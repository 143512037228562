import React from 'react';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';
import { Container, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { Add, DeleteForever } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import { BlueInverseBtn } from '../Styled/StyledButtons';
import { WorkflowsManagementActions } from '../../actions/WorkflowsManagmentActions';
import { WorkspacesActions } from '../../actions/WokspacesActions';
import { Workspace } from '../../models/Workspaces/Workspace';
import { Workflow } from '../../models/Workflows/Workflows';
import { Alert } from '@material-ui/lab';

interface IState {
  workflows: Workflow[],
  workspaces: Workspace[],
  currentWorkspace: string,
  backdrop: boolean,
  errorAlert: boolean,
  alertErrors: string[],
}
export default class WorkflowsManagementComponent extends React.Component<any, IState> {
  state: IState = {
    workflows: [],
    workspaces: [],
    currentWorkspace: '',
    backdrop: true,
    errorAlert: false,
    alertErrors: [],
  }
  componentDidMount() {
    document.title = 'Workflows'
    this.loadWorkspaces()
  }
  loadWorkspaces() {
    WorkspacesActions.getWorkspaces()
      .then(workspaces => {
        if (workspaces.length > 0) {
          this.setState({ workspaces: workspaces, currentWorkspace: workspaces[0].Sid })
          this.loadWorkflows()
        }
      })
  }
  
  loadWorkflows(){
    WorkflowsManagementActions.getWorkflows(this.state.currentWorkspace,)
    .then((workflows)=>{this.setState({workflows:workflows, backdrop: false})})
  }

  deleteWorkflow(sid: string){
    WorkflowsManagementActions.deleteWorkflow(this.state.currentWorkspace, sid)
    .then((data)=>{
      if(typeof data === 'boolean'){
      this.loadWorkflows()
      }else{
        let errors = (data.errors === undefined) ? [data.error] : data.errors
        this.setState({ errorAlert: true, alertErrors: errors })
      }
    })
  }
  handleCloseError() {
    this.setState({ errorAlert: false })
  }
  render() {
    return (
    <div>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className={mainStyles.Header}>Workflows Management</div>
                <div className={mainStyles.AgentName}> &nbsp;</div>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}><BlueInverseBtn href={'/workflow-create'} variant="contained" fullWidth={true}><Add /> Add Workflow</BlueInverseBtn></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Workflow name</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={mainStyles.FlexColScroll}>
                  {this.state.workflows.map((row, index) => (
                    <TableRow key={row.Sid}>
                      <TableCell align="left">{row.FriendlyName}</TableCell>
                      <TableCell align="right">
                        <Button href={'/workflow/sid/' + row.Sid} color="primary" ><CreateIcon /></Button>
                        <Button color="secondary" onClick={() => {this.deleteWorkflow(row.Sid) }}><DeleteForever /></Button>
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={this.state.errorAlert} autoHideDuration={5000} onClose={() => this.handleCloseError()} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                    <Alert onClose={() => this.handleCloseError()} severity="error" variant="filled">
                        Error<br />
                        <div className={styles.AlertError}>{this.state.alertErrors}</div>
                    </Alert>
                </Snackbar>
      <Backdrop className={styles.Backdrop} open={this.state.backdrop}>
          <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
          <p className={styles.BackdropProgress}>Loading...</p>
        </Backdrop>
    </div>
    )
  }
}

