export interface IDepartment{
     id: number;
    name: string
}
export class Department{
    private id:number
    
    get Id() : number {
        return this.id
    }
    
    private name: string    
    get Name() : string {
        return this.name
    }

    constructor(d:IDepartment){
        this.id = d.id
        this.name = d.name
    }    
}