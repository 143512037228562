import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, IconButton } from '@material-ui/core';
import React from 'react';
import ExpressionComponent from '../ExpressionComponent/ExpressionComponent';
import { BlueBtn, RedInverseBtn } from '../Styled/StyledButtons';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';
import ClearIcon from '@material-ui/icons/Clear';
import { ExpressionConfigProperty, IExpressionConfig } from '../../models/ExpressionBuilder/ExpressionBuilderTypes';
import { WorkersTargetConverter } from '../../models/ExpressionBuilder/WorkersTargetConverter';
import { AgentSkillsActions } from '../../actions/AgentSkillsActions';
import { GUID } from '../../models/Guid';
import { ExpressionValidator } from '../../models/ExpressionBuilder/ExpressionValidator';
import { HumanFriendlyExpressionConverter } from '../../models/ExpressionBuilder/HumanFriendlyExpressionConverter';

export interface IProps {
    expression_json: string
    editShow: boolean
    toggleHandle: () => void
    saveHandle: (expressioon: string) => void
}

interface IState {
    config: IExpressionConfig,
    defaultData: Object,
    expression_json: string,
    expression: string,
    errors: string[]
}

export default class OrderingExpressionComponent extends React.Component<IProps, IState> {

    state: IState = {
        config: {
            fields: {
                best_booker: { name: 'Best Booker', type: 'category', operators: ['='], options: [{ name: 'ASC', value: 'ASC' }, { name: 'DESC', value: 'DESC' }] },
                cancelation_ratio: { name: 'Cancelation ratio', type: 'category', operators: ['='], options: [{ name: 'ASC', value: 'ASC' }, { name: 'DESC', value: 'DESC' }] },
                best_sel: { name: 'Best Seller', type: 'category', operators: ['='], options: [{ name: 'ASC', value: 'ASC' }, { name: 'DESC', value: 'DESC' }] },
                adl: { name: 'ADL', type: 'category', operators: ['='], options: [{ name: 'ASC', value: 'ASC' }, { name: 'DESC', value: 'DESC' }] },
                avg_call_value_scaled: { name: 'AVG Call Value (scaled)', type: 'number', operators: ['=', '!=', '<=', '>=', '<', '>'] },
            }
        },
        defaultData: {
            condition: 'and',
            rules: [
            ]
        },
        expression_json: '',
        expression: '',
        errors: []
    }
    loadSkills() {
        AgentSkillsActions.getSkills()
            .then(skills => {
                let config = this.state.config
                let confProp = new ExpressionConfigProperty()
                for (let key in config.fields) {
                    confProp[key] = config.fields[key]
                }

                skills.forEach(element => {
                    confProp[element.Key] = { name: element.Key, type: 'category', operators: ['='], options: [{ name: 'ASC', value: 'ASC' }, { name: 'DESC', value: 'DESC' }] }
                });
                config.fields = confProp

                this.setState({
                    config: config
                })
            })
    }
    
    componentDidUpdate(prevProps: IProps) {
        if (this.props.expression_json !== prevProps.expression_json) {
            this.setState({ expression_json: this.props.expression_json });
        }
    }

    componentDidMount() {
        this.loadSkills()
    }

    handleExpression(exp: string) {
        let errors: string[] = ExpressionValidator.Validate(JSON.parse(exp))
        this.setState({ errors: errors, expression_json: exp, expression: WorkersTargetConverter.ConvertSortingToTwilio(JSON.parse(exp),'worker') })
    }

    save() {
        let errors: string[] = ExpressionValidator.Validate(JSON.parse(this.state.expression_json))
        this.setState({ errors: errors });
        if (errors.length === 0) {
            this.props.saveHandle(this.state.expression_json)
        }
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.editShow} onClose={this.props.toggleHandle}>
                    <DialogTitle>
                        Ordering
                        <IconButton className={mainStyles.floatRight} onClick={this.props.toggleHandle}>
                            <ClearIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                            <div className={`${styles.FormControl} ${styles.FullWidth}`}
                                 dangerouslySetInnerHTML={{ __html: (this.state.expression_json !== '') ? HumanFriendlyExpressionConverter.ConvertSortingToTwilio(JSON.parse(this.state.expression_json),'worker'):''}} />
                            </Grid>
                            <Grid item xs={12}>
                                <ul>
                                    {this.state.errors.map((error: string) => (
                                        <li className={styles.ExpressionError} key={GUID.New()}>{error}</li>
                                    ))}
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                <ExpressionComponent dataOut={(expr:string)=>this.handleExpression(expr)} data={this.state.expression_json || JSON.stringify(this.state.defaultData)} config={JSON.stringify(this.state.config)} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={1} >
                            <Grid item xs={6}  >
                                <RedInverseBtn fullWidth={true} onClick={this.props.toggleHandle}>Cancel</RedInverseBtn>
                            </Grid>
                            <Grid item xs={6}  >
                                <BlueBtn fullWidth={true} onClick={()=>this.save()}>Save</BlueBtn>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
