import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, IconButton } from '@material-ui/core';
import React from 'react';
import ExpressionComponent from '../ExpressionComponent/ExpressionComponent';
import { BlueBtn, RedInverseBtn } from '../Styled/StyledButtons';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';
import ClearIcon from '@material-ui/icons/Clear';
import { IExpressionConfig } from '../../models/ExpressionBuilder/ExpressionBuilderTypes';
import { ExpressionValidator } from '../../models/ExpressionBuilder/ExpressionValidator';
import { GUID } from '../../models/Guid';
import { HumanFriendlyExpressionConverter } from '../../models/ExpressionBuilder/HumanFriendlyExpressionConverter';

export interface IProps {    
    config: IExpressionConfig,
    defaultExpression: Object,
    expression_json: string
    editShow: boolean
    toggleHandle: () => void
    saveHandle: (expressioon: string) => void
}

interface IState {
    expression_json: string,
    expression: string,
    errors: string[]
}

export default class WorkflowExpresionComponent extends React.Component<IProps, IState> {

    state: IState = {
        expression_json: '',
        expression: '',
        errors: []
    }
    
    componentDidMount() {
        this.setState({ expression_json: this.props.expression_json });
    }
    
    componentDidUpdate(prevProps: IProps) {
        if (this.props.expression_json !== prevProps.expression_json) {
            this.setState({ expression_json: this.props.expression_json });
        }
    }

    handleExpression(exp: string) {
        let errors: string[] = ExpressionValidator.Validate(JSON.parse(exp))
        this.setState({ errors: errors, expression_json: exp, expression: HumanFriendlyExpressionConverter.ConvertToTwilio(JSON.parse(exp),'task',this.props.config) })
    }

    save() {
        let errors: string[] = ExpressionValidator.Validate(JSON.parse(this.state.expression_json))
        this.setState({ errors: errors });
        if (errors.length === 0) {
            this.props.saveHandle(this.state.expression_json)
        }
    }
    
    render() {
        return (
            <div>
                <Dialog open={this.props.editShow} onClose={this.props.toggleHandle}>
                    <DialogTitle>
                        Expression
                        <IconButton className={mainStyles.floatRight} onClick={this.props.toggleHandle}>
                            <ClearIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                            <div className={`${styles.FormControl} ${styles.FullWidth}`}
                                 dangerouslySetInnerHTML={{ __html: (this.state.expression_json !== '') ? HumanFriendlyExpressionConverter.ConvertToTwilio(JSON.parse(this.state.expression_json),'task',this.props.config):''}} />
                            </Grid>
                            <Grid item xs={12}>
                                <ul>
                                    {this.state.errors.map((error: string) => (
                                        <li className={styles.ExpressionError} key={GUID.New()}>{error}</li>
                                    ))}
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                <ExpressionComponent dataOut={(expr:string)=>this.handleExpression(expr)} data={this.state.expression_json || JSON.stringify(this.props.defaultExpression)} config={JSON.stringify(this.props.config)} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={1} >
                            <Grid item xs={6}  >
                                <RedInverseBtn fullWidth={true} onClick={this.props.toggleHandle}>Cancel</RedInverseBtn>
                            </Grid>
                            <Grid item xs={6}  >
                                <BlueBtn fullWidth={true} onClick={() => this.save() }>Save</BlueBtn>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
