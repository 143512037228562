import Api from "./../config/api";
import { IUser, User } from "../models/Layout/User/User";
import { IError } from "../models/IError";

interface IAxiosUser{
    data: IUser
  }
  interface IAxiosError {
    response: { data: IError, status: number }
  }
export class AuthActions{
        static getUser():Promise<User | IError>{
            return Api.get("users")
        .then((res:IAxiosUser):User=>{return new User(res.data)})
        .catch((res: IAxiosError)=>{       
            return res.response.data 
         })       
    }
} 