import { Container, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress } from '@material-ui/core';
import { Add, DeleteForever } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import { BlueInverseBtn } from '../Styled/StyledButtons';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import React from 'react';
import styles from './styles.module.css'
import { SpamFilter } from '../../models/SpamFilter/SpamFilter';
import { SpamFilterActions } from '../../actions/SpamFilterActions';
import SpamFilterEditComponent from './SpamFilterEditComponent';

interface IState {
    filters: SpamFilter[],
    editShow: boolean,
    currentFilter: SpamFilter,
    backdrop: boolean,
}
export default class SpamFilterComponent extends React.Component {

    state: IState = {
        filters: [],
        editShow: false,
        currentFilter: new SpamFilter('', ''),
        backdrop: true,
    }
    componentDidMount() {
        document.title = 'Spam Filters'
        this.load()
    }

    load() {
        SpamFilterActions.get()
            .then(filters => { this.setState({ filters: filters, backdrop: false }) })
    }

    toggleEdit() {
        this.setState({ editShow: !this.state.editShow })
    }

    savedHandle() {
        this.load()
        this.setState({ editShow: false })
    }

    addFilter() {
        this.setState({ currentFilter: new SpamFilter('', ''), editShow: true })
    }
    editFilter(filter: SpamFilter) {
        this.setState({ currentFilter: filter, editShow: true })
    }

    toggleActivity(id: number, isEnabled: number) {
        SpamFilterActions.toggleActivity(id, (isEnabled === 1))
            .then(filter => {
                this.load()
            })
    }
    delete(id: number) {
        SpamFilterActions.delete(id)
            .then(filter => {
                this.load()
            })
    }

    render() {
        return (
            <div>
                <Container maxWidth="lg">
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <div className={mainStyles.Header}>Spam Filters Management</div>
                                    <div className={mainStyles.AgentName}> &nbsp;</div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3}><BlueInverseBtn onClick={() => this.addFilter()} variant="contained" fullWidth={true}><Add /> Add Filter</BlueInverseBtn></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={mainStyles.WorkerSkillsBack}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell align="left">RegEx</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={mainStyles.FlexColScroll}>
                                        {this.state.filters.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left">{row.order === 1?'Allow':'Deny'} {row.regex}</TableCell>
                                                <TableCell>
                                                    <div className={styles.SwitchContainer}>
                                                        <div className={styles.Switch} >
                                                            <input type="radio" id={"switch_left_" + row.id} name={"switch" + row.id} value="0" checked={!row.active} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { if (row.id) { this.toggleActivity(row.id, parseInt(event.target.value)) } }} />
                                                            <label htmlFor={"switch_left_" + row.id}>Disabled</label>
                                                            <input type="radio" id={"switch_right_" + row.id} name={"switch" + row.id} value="1" checked={row.active} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { if (row.id) { this.toggleActivity(row.id, parseInt(event.target.value)) } }} />
                                                            <label htmlFor={"switch_right_" + row.id} >Enabled</label>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Button color="primary" onClick={() => this.editFilter(row)}><CreateIcon /></Button>
                                                    <Button color="secondary" onClick={() => { if (row.id) { this.delete(row.id) } }}><DeleteForever /></Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>
                <SpamFilterEditComponent filter={this.state.currentFilter} editShow={this.state.editShow} toggleHandle={() => this.toggleEdit()} savedHandle={() => this.savedHandle()} />
                <Backdrop className={styles.Backdrop} open={this.state.backdrop}>
                    <CircularProgress className={styles.BackdropProgress} />&nbsp;&nbsp;
                    <p className={styles.BackdropProgress}>Loading...</p>
                </Backdrop>
            </div>
        )
    }
}