import React from 'react';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css'
import { AgentChanges } from '../../models/AgentSkills/AgentChanges';

export interface IProps {
  agent: AgentChanges,
  handleChange: (name: string, checked: boolean) => void
}

export default class AgentKPIComponent extends React.Component<IProps> {
  _agentKpi = [
    { id: 'best_booker', name: 'Best Booker' },
    { id: 'cancelation_ratio', name: 'Cancelation ratio' },
    { id: 'best_sel', name: 'Best Seller' },
    { id: 'adl', name: 'ADL $200' },
  ];

  getAgentProp(name: string): boolean {
    let val: boolean

    switch (name) {
      case 'best_booker':
        val = this.props.agent.BestBooker
        break
      case 'cancelation_ratio':
        val = this.props.agent.CancelationRatio
        break
      case 'best_sel':
        val = this.props.agent.BestSel
        break
      case 'adl':
        val = this.props.agent.Adl
        break
      default:
        val = false;
        break
    }
    return val
  }

  changeCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    let id = event.target.value
    let checked = event.target.checked
    this.props.handleChange(id, checked)
  }
  render() {
    return (<div>
      <div className={mainStyles.ComponentHeader}><span >autocalculated KPI on-demand</span></div>
      <div className={mainStyles.ComponentBody} >
        {this._agentKpi.map((kpi, index) => {
          return (
            <label className={`${styles.SkillsCheckbox} ${styles.Underlined}`} key={kpi.id} >
              <input type="checkbox" value={kpi.id} checked={this.getAgentProp(kpi.id)} onChange={this.changeCheckbox.bind(this)} />
              <span className={`${(this.getAgentProp(kpi.id) ? styles.CheckLabelChecked : styles.CheckLabel)}`}>{kpi.name}</span>
            </label>
          )
        })}
      </div>
    </div>
    )
  }
}
