export class TaskChannelChanges{
    private sid:string = ''
    public get Sid() : string {
        return this.sid
    }    
    public set Sid(v : string) {
        this.sid = v;
    }
    
 
    private capacity: number = 0
    public get Capacity() : number {
        return this.capacity
    }    
    public set Capacity(v : number) {
        this.capacity = v;
    }
    
    
    private available: boolean = false
    
    public get Available() : boolean {
        return this.available
    }    
    public set Available(v : boolean) {
        this.available = v;
    }
    
      
 }