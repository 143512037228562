import Api from "./../config/callcenterserver";

interface IAxiosTasks {
    data: any[]
}

export class TasksActions {
    static getTasks(): Promise<any[]> {
        return Api.get("/tasks")
            .then((res: IAxiosTasks) => { return res.data })
    }
}