import { Agent } from "../AgentSkills/Agent"

export interface ITeam {
    id: number
    name: string
    agents: Agent[]
}

export class Team {
    private id: number = 0
    private name: string = ''
    private agents: Agent[] = []

    constructor(o: ITeam | undefined) {
        if (o !== undefined) {
            this.id = o.id
            this.name = o.name
            this.agents = o.agents
        }
    }

    public get Id(): number {
        return this.id
    }
    
    public get Name() : string {
        return this.name
    }
    
    
    public set Name(v : string) {
        this.name = v;
    }
    

    public get Agents() : Agent[] {
        return this.agents
    }
    
}