import { Expression, ExpressionRule } from "./ExpressionBuilderTypes";

export class QueuesWorkersTargetConverter {

    public static ConvertToTwilio(expression: Expression, prefix: string = '') {
        let arr: string[] = []

        if (expression.rules.length === 0) {
            return '1==1'
        }

        expression.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                arr.push(this.parseRule(item, prefix))
            }
            if (item.condition !== undefined) {
                let subArr = this.parseExpression(item, prefix)
                if (subArr) {
                    arr.push(subArr)
                }
            }
        });

        return arr.join(' ' + expression.condition + ' ')
    }

    private static parseRule(element: ExpressionRule, prefix: string = '') {
        let value = element.value

        const braces = (typeof value === 'string') ? "'" : ""
        if (element.operator === 'has') {
            return ((prefix !== '') ? prefix + '.' : '') + element.field + " " + element.operator + " " + braces + value + braces
        }
        if (element.operator === 'in' || element.operator === 'not in') {
            if (Array.isArray(value)) {
                return '(' + (value as Array<number | string | boolean>).map((elem: (number | string | boolean)) => { return this.getVal(elem) + ' ' + element.operator + ' ' + ((prefix !== '') ? prefix + '.' : '') + element.field }).join(' or ') + ')'
            } else {
                value = this.getVal(value) + ' ' + element.operator + ' ' + ((prefix !== '') ? prefix + '.' : '') + element.field
            }
            return ((prefix !== '') ? prefix + '.' : '') + element.field + " " + element.operator + " " + braces + value + braces
        }
        return ((prefix !== '') ? prefix + '.' : '') + element.field + " " + (element.operator === '=' ? '==' : element.operator) + " " + braces + value + braces
    }

    private static getVal(elem: (number | string | boolean)): string {
        if (typeof elem === 'number') {
            return elem.toString()
        }
        if (typeof elem === 'boolean') {
            return (elem) ? 'true' : 'false'
        }
        return '\'' + elem + '\''
    }

    private static parseExpression(element: Expression, prefix: string = '') {
        let arr: string[] = []

        if (element.rules.length === 0) {
            return false
        }

        element.rules.forEach((item: any) => {
            if (item.condition === undefined) {
                arr.push(this.parseRule(item, prefix))
            }
            if (item.condition !== undefined) {
                let subArr = this.parseExpression(item, prefix)
                if (subArr) {
                    arr.push(subArr)
                }
            }
        });
        return '(' + arr.join(' ' + element.condition + ' ') + ')'
    }
}