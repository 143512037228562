import Api from "./../config/api";
import { IQueue, Queue } from "../models/Queue/Queue";
import { IError } from "../models/IError";
import history from './../components/history';

interface IAxiosQueues {
    data: IQueue[]
}

interface IAxiosQueue {
    data: IQueue
}

interface IAxiosError {
    response: { data: IError, status: number }
}

export class QueuesManagementActions {

    static getQueues(workspaceId: string): Promise<Queue[]> {
        return Api.get(`/workspaces/${workspaceId}/queues`)
            .then((res: IAxiosQueues): Queue[] => { return res.data.map((d: IQueue): Queue => { return new Queue(d) }) })
    }
    static getQueue(workspaceSid: string, queueSid: string): Promise<Queue | IError> {
        return Api.get("/workspaces/" + workspaceSid + "/queues/" + queueSid)
            .then((res: IAxiosQueue): Queue => { return new Queue(res.data) })
            .catch((res:IAxiosError):IError=> { if(res.response.status === 404){history.replace("/404");} return res.response.data})
    }
    static createQueue(workspaceSid: string, queue: Queue): Promise<Queue | IError> {
        return Api.post("/workspaces/" + workspaceSid + "/queues", queue)
            .then((res: IAxiosQueue): Queue => { return new Queue(res.data) })
            .catch((res:IAxiosError):IError=> { console.log('Error', res.response.data.full_error); return res.response.data})
    }
    static updateQueue(workspaceSid: string, queueSid: string, queue: Queue): Promise<Queue | IError> {
        return Api.put("/workspaces/" + workspaceSid + "/queues/" + queueSid, queue)
            .then((res: IAxiosQueue): Queue => { return new Queue(res.data) })
            .catch((res:IAxiosError):IError=> { console.log('Error', res.response.data.full_error); return res.response.data})
    }
    static deleteQueue(workspaceSid: string, queueuSid: string): Promise<boolean | IError> {
        return Api.delete("/workspaces/" + workspaceSid + "/queues/" + queueuSid)
            .then((res): boolean => { return res.data.sid !== undefined })
            .catch((res:IAxiosError):IError=> { console.log('Error', res.response.data.full_error); return res.response.data})
    }
}