import { Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { TasksActions } from '../../actions/TasksActions';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';

export interface IProps {
}
interface IState {
    tasks: any[]
    backdrop: boolean
}
export default class TasksComponent extends React.Component<IProps, IState> {

    state: IState = {
        tasks: [],
        backdrop: false
    }

    componentDidMount() {
        document.title = 'Tasks'
        this.setState({ backdrop: true })
        this.loadTasks()
    }
    loadTasks() {
        TasksActions.getTasks().then((resp) => {
            this.setState({ tasks: resp, backdrop: false })
        })
    }

    secondsToHms(d: number) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h === 1 ? " h, " : " h, ") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " m, " : " m, ") : "";
        var sDisplay = s > 0 ? s + (s === 1 ? " s" : " s") : "";
        return hDisplay + mDisplay + sDisplay;
    }

    reservation(reservations: any) {
        if (reservations.length > 0) {
            return reservations[reservations.length - 1].workerName
        } else {
            return ''
        }

    }

    attr(attributes: any) {
        const attr = JSON.parse(attributes)
        return (
            <div>
                from: {attr.from}<br />
                to: {attr.to}<br />
            </div>
        )
    }

    render() {
        return (
            <div>
                <Container maxWidth="lg">
                    <Grid container spacing={1}>
                        <Grid item xs={12} >
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Status</TableCell>
                                            <TableCell align="left">Task Sid</TableCell>
                                            <TableCell align="left">Task Channel</TableCell>
                                            <TableCell align="left">Queue Name</TableCell>
                                            <TableCell align="left">Workflow Name</TableCell>
                                            <TableCell align="left">Age</TableCell>
                                            <TableCell align="left">Task Info</TableCell>
                                            <TableCell align="right">Reservation</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={mainStyles.FlexColScroll}>
                                        {this.state.tasks.map((row, index) => (
                                            <TableRow key={row.Sid}>
                                                <TableCell align="left">{row.assignmentStatus}</TableCell>
                                                <TableCell align="left">{row.sid}</TableCell>
                                                <TableCell align="left">{row.taskChannelUniqueName}</TableCell>
                                                <TableCell align="left">{row.taskQueueFriendlyName}</TableCell>
                                                <TableCell align="left">{row.workflowFriendlyName}</TableCell>
                                                <TableCell align="left">{this.secondsToHms(row.age)}</TableCell>
                                                <TableCell align="left">{this.attr(row.attributes)}</TableCell>
                                                <TableCell align="right">
                                                    {this.reservation(row.reservations)}
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {this.state.tasks.map((row, index) => (
                                <option key={row.Id} value={row.Id} >{row.Name}</option>
                            ))}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
}