import React from 'react';
import mainStyles from '../WorkerManagementComponent/WorkerManagementComponent.module.css';
import styles from './styles.module.css';

export interface IProps {
  userRoles: string[],
  handleChange: (role: string, checked: boolean) => void
}

export default class AccessTypeComponent extends React.Component<IProps> {
  _accessTypes = [
    { id: 'agent', name: 'Agent' },
    { id: 'supervisor', name: 'Supervisor' },
    { id: 'admin', name: 'Admin' },
    { id: 'wfo.full_access', name: 'WFO Full Access' },
    { id: 'wfo.team_leader', name: 'WFO Team Leader' },
    { id: 'wfo.data_analyst', name: 'WFO Data Analyst' },
    { id: 'wfo.data_auditor', name: 'WFO Data Auditor' },
    { id: 'wfo.quality_manager', name: 'WFO Quality Manager' },
    { id: 'wfo.quality_process_manager', name: 'WFO Quality Process Manager' },
    { id: 'wfo.dashboard_viewer', name: 'Dasboard Viewer' },
  ];
  state = {
    AccessType: 0
  }

  valid() {
    return this.props.userRoles.length > 0
  }
  render() {
    let error = <div></div>
    if (!this.valid()) { error = <div className={styles.Error}>Select one or more items</div> }
    return (
      <div>
        <div className={mainStyles.ComponentHeader}><span >access to flex as:</span></div>
        <div className={`${mainStyles.ComponentBody} ${mainStyles.Scrollable} ${(!this.valid()) ? styles.ComponentInvalid : ''} `} >
          {error}
          {this._accessTypes.map((type, index) => {
            return (
              <label className={`${styles.SkillsCheckbox} ${styles.Underlined}`} key={type.id} >
                <input type="checkbox" value={type.id} checked={(this.props.userRoles.indexOf(type.id) >= 0)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleChange(event.target.value, event.target.checked)} />
                <span className={`${((this.props.userRoles.indexOf(type.id) >= 0) ? styles.CheckLabelChecked : styles.CheckLabel)}`}>{type.name}</span>
              </label>
            )
          })
          }
        </div>
      </div>
    )
  }
}
